import styled from '@emotion/styled';

import { screen } from '@/components/common/breakpoints';
import { stringToId } from '@/components/common/utils';

import { SubNavigationColumn } from './SubNavigationColumn';

import type { SubNavigationColumnProps } from './SubNavigationColumn';

export interface ColumnDataProps {
  columnOne: SubNavigationColumnProps;
  columnTwo: SubNavigationColumnProps;
  columnThree: SubNavigationColumnProps[];
}
export interface SubNavigationProps {
  title: string;
  columns: ColumnDataProps;
  isOpen: boolean;
}

export const SubNavigation = ({
  title,
  columns,
  isOpen,
}: SubNavigationProps) => {
  const { columnOne, columnTwo, columnThree } = columns;
  const lastColumn = columnThree?.length > 0 ? columnThree[0] : null;
  const titleString = title ? `-${stringToId(title)}` : null;

  return (
    <Container id={`sub-navigation${titleString}`}>
      <SubNavigationColumn {...columnOne} isOpen={isOpen} />
      <SubNavigationColumn {...columnTwo} isOpen={isOpen} />
      <SubNavigationColumn {...lastColumn} isOpen={isOpen} />
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  ${screen.md} {
    padding-top: var(--spacing-600);
    padding-bottom: var(--spacing-500);
    padding-inline: var(--spacing-400);

    grid-template-columns: repeat(3, 1fr);
    grid-gap: var(--spacing-600);
  }
`;
