import { createContext } from 'react';

export const HeadingContainerContext = createContext({
  containerRef: null,
});

export const HeadingLevelContext = createContext(1);

export const HeadingLevelProvider = ({ level = 1, children }) => (
  <HeadingLevelContext.Provider value={level}>
    {children}
  </HeadingLevelContext.Provider>
);
