import { colours, colourTokens, flattenObjectToArray } from '@/styles/colours';

import type { ButtonVariant } from '@/components/common/Buttons/shared';
import type {
  BackgroundColourTokenType,
  BackgroundColourType,
  CombinedTokenType,
  DecorativeColourType,
  SurfaceColourTokenType,
  SurfaceColourType,
} from '@/types/colours';

const lightBackgroundColours = [
  '--background-subtle',
  '--background-subtle',
  '--background-warm-base',
  '--background-warm-strong',
  '--background-cool-base',
  '--background-cool-strong',
  '--surface-subtle',
  '--surface-warm-base',
  '--surface-warm-strong',
  '--surface-cool-base',
  '--surface-cool-strong',
  '--surface-primary-subtle',
  '--surface-danger-subtle',
  '--surface-eclipse',
  '--surface-dark-eclipse',
  '--surface-sunbeam',
  '--surface-dark-sunbeam',
  '--surface-light-galaxy',
  '--surface-light-ultraviolet',
  '--decorative-starlight',
  '--decorative-eclipse',
  '--decorative-dark-eclipse',
  '--decorative-dark-sunbeam',
  '--decorative-sunbeam',
  '--decorative-green-tint',
  '--decorative-blue-tint',
  '--decorative-yellow',
  '--decorative-yellow-tint',
  '--decorative-white',
  // '--icon-inverse-strong',
  // '--icon-warm-base',
  // '--icon-warm-subtle',
];

const darkBackgroundColours = [
  '--background-primary',
  '--surface-inverse-subtle',
  '--surface-inverse-base',
  '--surface-inverse-strong',
  '--surface-primary',
  '--surface-dark-ultraviolet',
  '--surface-ultraviolet',
  '--surface-inverse-primary',
  '--surface-danger-strong',
  '--surface-inverse-danger',
  '--surface-galaxy',
  '--surface-ultraviolet',
  '--surface-dark-ultraviolet',
  '--decorative-ultraviolet',
  '--decorative-dark-ultraviolet',
  '--decorative-galaxy',
  '--decorative-soft-galaxy',
  '--decorative-green',
  '--decorative-red',
  '--decorative-red-tint',
  '--decorative-blue',
  // '--icon-strong',
  // '--icon-warm-base',
  // '--icon-warm-subtle',
  // '--icon-action',
  // '--icon-action-dark',
  // '--icon-inverse-action',
  // '--icon-danger-base',
  // '--icon-inverse-danger',
  // '--icon-galaxy',
];

export const getBulletPointColour = <
  T extends
    | SurfaceColourType['token']
    | BackgroundColourType['token']
    | DecorativeColourType['token'],
>(
  backgroundColour: T,
) => {
  if (lightBackgroundColours.includes(backgroundColour)) {
    return '--decorative-ultraviolet';
  } else if (darkBackgroundColours.includes(backgroundColour)) {
    return '--icon-inverse-warm-base';
  }
};

export const getTextColour = <
  T extends
    | SurfaceColourType['token']
    | BackgroundColourType['token']
    | DecorativeColourType['token'],
  // | IconColourType['token'],
>(
  backgroundColour: T,
  options?: {
    variation?: 'strong' | 'base' | 'subtle';
    action?: boolean;
    isCaption?: boolean;
    subTitle?: boolean;
  },
) => {
  const { variation = 'strong' } = options || { variation: 'strong' };
  if (
    backgroundColour == '--surface-inverse-subtle' ||
    backgroundColour == '--surface-inverse-base'
  ) {
    return '--text-eclipse';
  } else if (lightBackgroundColours.includes(backgroundColour)) {
    if (variation === 'strong') {
      return '--text-strong';
    }
    if (
      variation === 'base' ||
      options?.action ||
      options?.isCaption ||
      options?.subTitle
    ) {
      return '--text-warm-base';
    }
    if (variation === 'subtle') {
      return '--text-warm-subtle';
    }
  } else if (darkBackgroundColours.includes(backgroundColour)) {
    if (variation === 'strong') {
      return '--text-inverse-strong';
    }
    if (variation === 'base') {
      return '--text-inverse-warm-base';
    }
    if (options?.subTitle) {
      return '--text-inverse-warm-base';
    }
    if (variation === 'subtle') {
      return '--text-inverse-warm-subtle';
    }
  }
};

export const isLightBackground = <
  T extends
    | SurfaceColourType['token']
    | BackgroundColourType['token']
    | DecorativeColourType['token'],
>(
  backgroundColour: T,
): boolean => {
  return lightBackgroundColours.includes(backgroundColour);
};

export const isDarkBackground = <
  T extends
    | SurfaceColourType['token']
    | BackgroundColourType['token']
    | DecorativeColourType['token'],
>(
  backgroundColour: T,
): boolean => {
  return darkBackgroundColours.includes(backgroundColour);
};

export const inferButtonComponentTheme = (
  theme: 'dark' | 'light' = 'light',
  originalVariant: 'primary' | 'secondary' = 'primary',
): ButtonVariant => {
  const variantMap = {
    primary: 'solid',
    secondary: 'flat',
  } as const;

  return `${variantMap[originalVariant]}-${theme}`;
};

export const getButtonComponentThemeSolid = <
  T extends
    | SurfaceColourType['token']
    | BackgroundColourType['token']
    | DecorativeColourType['token'],
>(
  backgroundColour: T,
): ButtonVariant => {
  if (
    isDarkBackground(backgroundColour) ||
    backgroundColour === '--background-primary' ||
    backgroundColour === '--surface-primary'
  ) {
    return 'solid-dark';
  }
  return 'solid-light';
};
export const getButtonComponentThemeFlat = <
  T extends
    | SurfaceColourType['token']
    | BackgroundColourType['token']
    | DecorativeColourType['token'],
>(
  backgroundColour: T,
): ButtonVariant => {
  if (
    isDarkBackground(backgroundColour) ||
    backgroundColour === '--background-primary' ||
    backgroundColour === '--surface-primary'
  ) {
    return 'flat-dark';
  }
  return 'flat-light';
};

export const getColourFromToken = (token: CombinedTokenType) => {
  const flatTokens = flattenObjectToArray(colourTokens);
  const flatColours = flattenObjectToArray(colours);

  return [...flatTokens, ...flatColours].find((item) => item.token === token)
    .colour;
};

/*
This function is used for getting background colour for the child blocks/cards of components.
  If the background colour of a parent is --background-subtle, then child blocks will be --surface-cool-base
  If the background colour of a parent is other,               then child blocks will be --surface-subtle

Parameters:
- parentBackgroundColour [required] -> accepts background colour of a parent as the main configuration
to determine the colour correspondence
- childSecondColourToken [optional] -> accepts colour token to override the default colour of a child when
background is --background-subtle
*/
export const getChildBackgroundColourToken = (
  parentBackgroundColourToken:
    | BackgroundColourTokenType
    | SurfaceColourTokenType,
  childSecondColourToken?: BackgroundColourTokenType | SurfaceColourTokenType,
): BackgroundColourTokenType | SurfaceColourTokenType => {
  const defaultSecondColourToken = colourTokens.surface.coolBase.token;
  childSecondColourToken = childSecondColourToken ?? defaultSecondColourToken;

  return parentBackgroundColourToken === colourTokens.background.subtle.token
    ? childSecondColourToken
    : colourTokens.surface.subtle.token;
};
