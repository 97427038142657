import styled from '@emotion/styled';

import { screen } from '@/components/common/breakpoints';
import { RichHeading } from '@/components/common/Heading';
import { Heading, HeadingLevel } from '@/components/common/MarkUp';
import { stringToId } from '@/components/common/utils';
import { StandoutCTAList } from '@/components/layout/NavigationBar/Standout/StandoutCTA';
import { SubNavigationLink } from '@/components/layout/NavigationBar/SubNavigation/SubNavigationLink';

import type { HeadingSanityBlockType } from '@/components/common/Heading';
import type { StandoutBlock } from '@/components/layout/NavigationBar/Standout/StandoutCTA';
import type { SubNavigationLinkProps } from '@/components/layout/NavigationBar/SubNavigation/SubNavigationLink';

export type BottomNavLink = {
  title: string;
  url: string;
  _type: string;
  tabIndex?: number;
};

export interface SubNavigationColumnProps {
  _type?: string;
  heading?: HeadingSanityBlockType;
  caption?: string;
  links?: SubNavigationLinkProps[];
  hideTextOnMobile?: boolean;
  bottomLink?: BottomNavLink;
  standoutBlocks?: StandoutBlock[];
  variant?: 'employers' | 'individuals';
  isOpen?: boolean;
  isSubNavOpen?: boolean;
}

export const SubNavigationColumn = ({
  heading,
  caption,
  links,
  hideTextOnMobile = false,
  bottomLink,
  standoutBlocks,
  variant,
  isOpen,
}: SubNavigationColumnProps) => {
  const captionId = (caption && `caption-${stringToId(caption)}`) || null;
  return (
    <Column hasStandoutBlocks={!!standoutBlocks} variant={variant}>
      {!!heading && (
        <HeadingWrapper>
          <RichHeading
            size="heading-lg"
            elementType="paragraph"
            heading={heading}
          />
        </HeadingWrapper>
      )}
      {caption && (
        <HeadingLevel>
          <Caption size="label-md" id={captionId}>
            {caption}
          </Caption>
        </HeadingLevel>
      )}
      {links?.length > 0 && (
        <ul aria-labelledby={captionId}>
          {links.map((link) => (
            <LinkItem hideTextOnMobile={hideTextOnMobile} key={link.title}>
              <SubNavigationLink tabIndex={isOpen ? 0 : -1} {...link} />
            </LinkItem>
          ))}
          {bottomLink?.title && (
            <BottomLinkItem key={bottomLink.title}>
              <SubNavigationLink tabIndex={isOpen ? 0 : -1} {...bottomLink} />
            </BottomLinkItem>
          )}
        </ul>
      )}
      {!!standoutBlocks && (
        <StandoutBlockWrapper>
          <StandoutCTAList cards={standoutBlocks} />
        </StandoutBlockWrapper>
      )}
    </Column>
  );
};

const Column = styled.div<{
  hasStandoutBlocks: boolean;
  variant?: 'employers' | 'individuals';
}>`
  & + & {
    margin-top: var(--spacing-medium);
  }

  ${screen.md} {
    & + & {
      margin-top: 0;
    }
  }
`;

const LinkItem = styled.li<{ hideTextOnMobile: boolean }>`
  & + & {
    margin-top: var(--spacing-small);
  }
  p {
    ${(props) => (props.hideTextOnMobile ? 'display: none' : '')};
  }
`;

const HeadingWrapper = styled.div`
  margin-bottom: var(--spacing-small);
  font-weight: var(--font-weight-medium);
`;

const StandoutBlockWrapper = styled.div`
  display: none;

  ${screen.md} {
    display: block;
  }
`;

const BottomLinkItem = styled.li`
  margin-top: var(--spacing-small);
`;

const Caption = styled(Heading)`
  color: var(--text-strong);

  margin-bottom: var(--spacing-xx-small);
`;
