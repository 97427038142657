import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { getImageProps } from 'next/image';

import { getSanityImageUrl } from '@/lib/sanityUtils';
import { usePageCommon } from '@/lib/usePageCommon';

import { WidthConstraint } from './common';

import type {
  BackgroundColourTokenType,
  TextColourTokenType,
} from '@/types/colours';
import type { ReactNode } from 'react';
import type { SanityImageCrop, SanityImageHotspot } from 'sanity-codegen';

type verticalPaddingProps = boolean | 'default' | 'floating' | 'tight';

interface SectionProps {
  className: string;
  backgroundColour?: BackgroundColourTokenType;
  backgroundImage?: {
    _type: 'image';
    crop?: SanityImageCrop;
    hotspot?: SanityImageHotspot;
  };
  backgroundImageMobile?: {
    _type: 'image';
    crop?: SanityImageCrop;
    hotspot?: SanityImageHotspot;
  };
  backgroundPosition?: string;
  backgroundPositionMobile?: string;
  colour?: TextColourTokenType;
  children?: ReactNode;
  breakout?: boolean;
  verticalPadding?: verticalPaddingProps;
  priority?: boolean;
  hideCurve?: boolean;
}

export const HeroSection = ({
  children,
  backgroundColour = '--background-subtle',
  backgroundImage,
  backgroundImageMobile,
  backgroundPosition,
  backgroundPositionMobile,
  colour,
  verticalPadding = false,
  breakout = false,
  className,
  priority = false,
  hideCurve = false,
}: SectionProps) => {
  const { layout } = usePageCommon();

  const image = backgroundImage && getSanityImageUrl(backgroundImage);
  const mobileImage =
    backgroundImageMobile && getSanityImageUrl(backgroundImageMobile);

  const common = { alt: '', sizes: '100vw' };

  const {
    props: { srcSet: desktop },
  } = getImageProps({
    ...common,
    quality: 80,
    fill: true,
    src: image,
    priority: priority,
  });
  const {
    props: { srcSet: mobile, ...rest },
  } = getImageProps({
    ...common,
    quality: 70,
    fill: true,
    src: mobileImage ?? image,
    priority: priority,
  });

  const innerComponent = breakout ? (
    children
  ) : (
    <WidthConstraint>{children}</WidthConstraint>
  );

  return (
    <HeroSectionElem
      verticalPadding={verticalPadding}
      backgroundColour={backgroundColour}
      colour={colour}
      className={className}
    >
      {image && (
        <picture>
          {mobileImage && (
            <>
              <source media="(max-width: 960px)" srcSet={mobile} />
              <source media="(min-width: 961px)" srcSet={desktop} />
            </>
          )}
          {!mobileImage && <source srcSet={desktop} />}
          <SectionImage
            {...rest}
            alt=""
            backgroundPosition={backgroundPosition ?? 'center'}
            backgroundPositionMobile={backgroundPositionMobile ?? 'center'}
          />
        </picture>
      )}
      {innerComponent}
      {!hideCurve && (
        <HeroCurve
          style={{
            backgroundColor:
              layout?.firstItemBackground && layout.spacingMode !== 'margin'
                ? `var(${layout?.firstItemBackground})`
                : undefined,
          }}
        />
      )}
    </HeroSectionElem>
  );
};

const getVerticalPadding = (
  verticalPadding: SectionProps['verticalPadding'],
) => {
  switch (verticalPadding) {
    case 'tight':
      return css`
        padding-top: calc(var(--full-navbar-height) + var(--spacing-x-large));
        padding-bottom: var(--section-radius);
      `;

    case 'floating':
      return css`
        padding-top: calc(var(--full-navbar-height) + var(--spacing-x-large));
        padding-bottom: calc(var(--section-radius) + var(--spacing-x-large));
      `;

    case true:
    case 'default':
      return css`
        padding-top: var(--navbar_ex-padding-top);
        padding-bottom: calc(var(--section-radius) + var(--spacing-x-large));
      `;

    case false:
    default:
      break;
  }
};

type SectionElemProps = Pick<
  SectionProps,
  'verticalPadding' | 'backgroundColour' | 'colour'
>;

export const HeroSectionElem = styled.section<SectionElemProps>`
  position: relative;
  overflow: clip;

  ${(props) => css`
    background-color: var(${props.backgroundColour});
    color: var(${props.colour});
  `}

  ${({ verticalPadding }) => getVerticalPadding(verticalPadding)}
`;

const HeroCurve = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: var(--section-radius);

  box-shadow: var(--shadow-base);
  border-top-left-radius: var(--section-radius);
  border-top-right-radius: var(--section-radius);

  background-color: var(--background-subtle);
`;

type SectionImageProps = Pick<
  SectionProps,
  'backgroundPosition' | 'backgroundPositionMobile'
>;

const SectionImage = styled.img<SectionImageProps>`
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  left: 0;
  top: 0;
  object-position: center;

  ${(props) =>
    props.backgroundPosition &&
    css`
      object-position: ${props.backgroundPosition};
    `}

  @media (max-width: 960px) {
    ${(props) =>
      props.backgroundPositionMobile &&
      css`
        object-position: ${props.backgroundPositionMobile};
      `}
  }
`;
