import { useContext } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { HeadingLevelContext } from '@/components/common/Heading/context';
import { bodyMedium, typographyStyles } from '@/styles/typography';

import type { TypographySizeTypes } from '@/styles/typography';
import type { TextColourTokenType } from '@/types/colours';
import type { ElementType, HTMLAttributes } from 'react';

export interface HeadingProps extends HTMLAttributes<HTMLHeadingElement> {
  size?: TypographySizeTypes;
  colour?: TextColourTokenType;
  fontWeight?: 'regular' | 'medium' | 'semibold';
  elementType?: 'span' | 'paragraph';
}

interface ParagraphProps extends HTMLAttributes<HTMLParagraphElement> {
  size?: TypographySizeTypes;
  colour?: TextColourTokenType;
}

export interface ListProps extends ParagraphProps {
  type?: 'ul' | 'ol';
}

export const SizeStyle = (props: HeadingProps) => {
  return typographyStyles[props.size];
};

const HeadingWithStyle = styled.div`
  label: HeadingWithStyle;
  ${SizeStyle}
  ${({ colour }) =>
    colour &&
    css`
      color: var(${colour});
    `}
  ${({ fontWeight }) =>
    fontWeight && `font-weight: var(--font-weight-${fontWeight})`};
  position: relative;
`;

const BaseHeading = ({
  size = 'subheading-lg',
  colour,
  fontWeight,
  elementType,
  children,
  ...props
}: HeadingProps) => {
  const level = Math.min(6, useContext(HeadingLevelContext));
  const getType = () => {
    if (elementType === 'paragraph') return 'p';
    if (elementType === 'span') return 'span';
    return `h${level}` as ElementType;
  };

  return (
    <HeadingWithStyle
      size={size}
      colour={colour}
      fontWeight={fontWeight}
      as={getType()}
      {...props}
    >
      {children}
    </HeadingWithStyle>
  );
};

const P = styled.p`
  ${SizeStyle};
  ${({ colour }) =>
    colour &&
    css`
      color: var(${colour});
    `}

  b,
  strong {
    font-weight: var(--font-weight-medium);
  }
  i,
  em {
    font-style: italic;
  }

  a {
    font-size: inherit;
    font-weight: inherit;
  }

  li {
    letter-spacing: -1px;
  }
`;

const StyledP = ({
  size = 'body-md',
  colour,
  children,
  ...props
}: ParagraphProps) => {
  return (
    <P size={size} colour={colour} {...props}>
      {children}
    </P>
  );
};

const StyledListElement = styled.div`
  ${SizeStyle}
  list-style: initial;
  span {
  }
  b,
  strong {
    font-weight: var(--font-weight-semibold);
  }
  i,
  em {
    font-style: italic;
  }

  li {
    margin-bottom: var(--spacing-xx-small);
    margin-left: var(--spacing-medium);
  }

  :where(ol) {
    list-style: decimal;

    li {
      margin-left: var(--spacing-large);
    }
  }
`;

const ListElement = ({ size, type = 'ul', children, ...props }: ListProps) => {
  return (
    <StyledListElement size={size} as={type} {...props}>
      {children}
    </StyledListElement>
  );
};

export const BlockQuote = styled.blockquote`
  ${bodyMedium}
  font-style: italic;
`;

export const HeadingLevel = ({ children }) => {
  const level = useContext(HeadingLevelContext);
  return (
    <HeadingLevelContext.Provider value={level + 1}>
      {children}
    </HeadingLevelContext.Provider>
  );
};

export const Heading = styled(BaseHeading)<HeadingProps>``;
export const Paragraph = styled(StyledP)<ParagraphProps>`
  label: Paragraph;
`;
export const List = styled(ListElement)<ListProps>``;
