import styled from '@emotion/styled';
import Link from 'next/link';

import { Image } from '@/components/common/image';
import { Paragraph } from '@/components/common/MarkUp';

import type { TrackingTagType } from '@/types/shared';
import type { SanityImageAsset, SanityReference } from 'sanity-codegen';

export type StandoutBlock = {
  caption?: string;
  title: string;
  url: string;
  trackingTag?: TrackingTagType;
  image?: {
    _type: 'image';
    asset: SanityReference<SanityImageAsset>;
    alternateText?: string;
  };
};

export interface StandoutCTAListProps {
  cards: StandoutBlock[];
}

export interface StandoutCTAProps {
  card: StandoutBlock;
}

export const StandoutCTAList = ({ cards }: StandoutCTAListProps) => {
  return cards.map((card) => <StandoutCTA card={card} key={card.title} />);
};

const StandoutCTA = ({ card }: StandoutCTAProps) => {
  const { caption, title, url, image } = card;
  const { blockName = 'standout', blockAction = 'cta' } =
    card.trackingTag ?? {};

  return (
    <CardContainer>
      <Link href={url} className={`tracking-${blockName}__${blockAction}`}>
        <CardContent>
          {image && image.asset && (
            <ImageContainer>
              <Image {...image} objectFit="cover" alt="" />
            </ImageContainer>
          )}
          {caption && (
            <CaptionContainer size="label-md">{caption}</CaptionContainer>
          )}
          <Wrapper>
            <HeadingContainer size="label-lg">{title}</HeadingContainer>
          </Wrapper>
        </CardContent>
      </Link>
    </CardContainer>
  );
};

const CaptionContainer = styled(Paragraph)`
  color: var(--text-strong);
  text-transform: none;
  font-weight: var(--font-weight-regular);
  padding: var(--spacing-medium) var(--spacing-small) 0;
  -webkit-line-clamp: 2;
`;

// This wrapper allows to use "-webkit-line-clamp".
// Otherwise, padding would allow text to sneak through it.
const Wrapper = styled.div`
  padding-bottom: var(--spacing-medium);
`;

const HeadingContainer = styled(Paragraph)`
  color: var(--text-strong);
  padding: var(--spacing-small) var(--spacing-small) 0;
  -webkit-line-clamp: 3;
`;

const CardContent = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  ${CaptionContainer}, ${HeadingContainer} {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  :not(:has(> ${CaptionContainer})) ${HeadingContainer} {
    padding-top: var(--spacing-medium);
  }
`;

const ImageContainer = styled.div`
  img {
    max-height: 225px;
  }
`;

const CardContainer = styled.div`
  border-radius: var(--radius-200);
  overflow: hidden;
  transition: all 0.3s;
  will-change: scale;

  background-color: var(--surface-primary-subtle);

  a {
    text-decoration: none;
  }

  & + & {
    margin-top: var(--spacing-small);
  }

  &:hover,
  &:focus-within {
    transform: scale(1.05);

    ${HeadingContainer} {
      text-decoration: underline;
    }
  }

  &:focus-within {
    outline: 2px solid var(--border-action);
    outline-offset: 2px;
  }
`;
