import { useCallback } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import clsx from 'clsx';
import { m } from 'framer-motion';
import Link from 'next/link';
import { useRouter } from 'next/router';

import {
  determineDestination,
  stringToId,
  VisuallyHidden,
} from '@/components/common/utils';
import { labelMedium } from '@/styles/typography';

import type { Dispatch, PointerEvent, SetStateAction } from 'react';

interface NavigationLinkProps {
  href: string;
  title: string;
  id: string;
  isActive?: boolean;
  isOtherActive: boolean;
  handleOpen: (key: string) => void;
  handleClose?: () => void;
  index: number;
  isFirstOpen?: boolean;
  setIsFirstOpen?: Dispatch<SetStateAction<boolean>>;

  linkOnly: boolean;
  navMouseEnter: (e: PointerEvent<HTMLAnchorElement>) => void;
}

const ConditionalNavLink = ({ children, ...props }): JSX.Element => {
  return props.href ? (
    <NavLink {...props} href={props.href} passHref>
      {children}
    </NavLink>
  ) : (
    <NavItemNoLink {...props}>{children}</NavItemNoLink>
  );
};

export const NavigationLink = ({
  href,
  title,
  id,
  handleOpen,
  handleClose,
  isActive,
  isOtherActive,
  index,
  linkOnly,
  isFirstOpen,
  setIsFirstOpen,

  navMouseEnter,
}: NavigationLinkProps) => {
  const destination = determineDestination(href, false);
  const { asPath } = useRouter();

  const ariaCurrent = href === asPath ? 'page' : null;
  const titleString = `-${stringToId(title)}`;

  const onA11yClick = useCallback(() => {
    if (isActive && handleClose) {
      handleClose();
    } else {
      handleOpen?.(id);
    }
  }, [isActive, handleClose, handleOpen, id]);

  const handleOnPointerEnter = (
    event: React.PointerEvent<HTMLAnchorElement>,
  ) => {
    navMouseEnter(event);
    if (linkOnly) {
      setIsFirstOpen(true);
      handleClose();
    } else {
      if (window.desktopNavTopLinkId !== id) {
        handleOpen?.(id);

        window.desktopNavTopLinkId = id;
      }
    }
    if (isFirstOpen && !linkOnly) {
      setIsFirstOpen(false);
    }
  };

  return (
    <LinkItemContainer>
      <ConditionalNavLink
        id={`sub-navigation${titleString}`}
        href={href}
        className={clsx('tracking-nav__link', { isActive })}
        target={destination.target}
        aria-label={title}
        aria-current={ariaCurrent}
        style={{ gridColumn: index + 1 }}
        tabIndex={isOtherActive ? -1 : 0}
        onPointerEnter={handleOnPointerEnter}
        onPointerLeave={() => {
          if (linkOnly) setIsFirstOpen(true);
          window.desktopNavTopLinkId = null;
        }}
        onClick={() => (window.desktopNavTopLinkId = id)}
      >
        {title}
      </ConditionalNavLink>

      {!linkOnly && (
        <VisuallyHidden>
          <AccessibilityButton
            type="button"
            aria-expanded={isActive}
            aria-controls={`sub-navigation${titleString}`}
            aria-label={`Open ${title} sub-navigation`}
            tabIndex={isOtherActive ? -1 : 0}
            onClick={() => handleOpen(id)}
            onKeyDown={(event) => {
              if (event.code === 'Space' || event.code === 'Enter') {
                event.preventDefault();
                onA11yClick();
              }
            }}
          />
        </VisuallyHidden>
      )}

      {isActive && <NavIndicator layoutId="nav-ex-indicator" />}
    </LinkItemContainer>
  );
};

const LinkItemContainer = styled.div`
  position: relative;
  grid-row: 1;
`;

const SharedNavStyling = css`
  ${labelMedium}
  color: var(--text-warm-base);
  padding: 15px var(--spacing-150) 14px;
  text-decoration: none;
  position: relative;
  z-index: 1;
  text-decoration-color: var(--border-warm-base);
  text-decoration-thickness: 1px;
  white-space: nowrap;

  &.isActive {
    transition: 0.45s;
    color: var(--text-strong);
    text-decoration: none;
    text-decoration-color: var(--border-warm-base);
    text-underline-offset: 3px;
  }

  &:hover {
    transition: color 0.45s;
    color: var(--text-strong);
    text-decoration: underline;
    text-decoration-color: var(--border-warm-base);
    text-underline-offset: 3px;
  }
`;

const NavLink = styled(Link)`
  ${SharedNavStyling}
`;

const NavItemNoLink = styled.span`
  ${SharedNavStyling}

  cursor: default;
`;

const AccessibilityButton = styled.button`
  all: unset;
  appearance: none;
  pointer-events: none;

  opacity: 0;

  width: 10px;
  height: 10px;

  &:focus {
    pointer-events: auto;
    opacity: 1;
  }
`;

const NavIndicator = styled(m.div)`
  width: 20px;
  bottom: -14px;
  height: 2px;
  left: calc(50% - 10px);
  position: absolute;

  background: rgba(0, 0, 0, 1);
`;
