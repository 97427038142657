import { forwardRef } from 'react';

import MultiverseLogoPrimarySvg from '@/public/images/logo-primary.svg';
import MultiverseLogoSvg from '@/public/images/logo-v2.svg';

import type { SVGProps } from 'react';

interface MultiverseLogoProps extends SVGProps<SVGElement> {
  version?: string;
}

export const MultiverseLogo = forwardRef(
  ({ className, version, ...props }: MultiverseLogoProps, ref) => {
    if (version === 'primary')
      return (
        <MultiverseLogoPrimarySvg
          className={className}
          aria-hidden="true"
          ref={ref}
          style={{ width: 123 }}
          {...props}
        />
      );
    return (
      <MultiverseLogoSvg
        className={className}
        aria-hidden="true"
        ref={ref}
        {...props}
      />
    );
  },
);
MultiverseLogo.displayName = 'MultiverseLogo';
