import styled from '@emotion/styled';

import { screen } from '@/components/common/breakpoints';

type Sizings = 'base' | 'sm' | 'md' | 'lg';
interface WidthConstraintProps {
  includePadding?: Sizings[];
}

const baseList = ['base', 'sm', 'md', 'lg'];
const fallback = '@media(min-width: 0)';

/**
 * Used to constrain the width of the content of Section
 * If using the breakout option of section, this can be used to add constraints back in
 *
 * @param {Array} includePadding A list of breakpoints where the external padding should be visible. Remove values to create a "full bleed" effect
 */
export const WidthConstraint = styled.div<WidthConstraintProps>`
  margin: 0 auto;
  max-width: var(--desktop-max-width);
  position: relative;

  ${({ includePadding = baseList }) => {
    // Use reduce to merge media queries together into a string.
    return baseList.reduce((acc, current) => {
      if (includePadding.includes(current)) {
        return `${acc} ${
          screen[current] ?? fallback
        } { padding: 0 var(--section-gutter) };`;
      }
      return `${acc} ${screen[current] ?? fallback} { padding: 0  };`;
    }, '');
  }}
`;
