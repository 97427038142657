import styled from '@emotion/styled';

import BaseLink from '@/components/common/BaseLink/BaseLink';

export interface PreviewBannerProps {}

const PreviewBanner = () => (
  <PreviewBannerContainer>
    You are in preview mode,{' '}
    <ExitLink href="/api/preview-disable" locale={false}>
      Exit preview mode
    </ExitLink>
  </PreviewBannerContainer>
);

const PreviewBannerContainer = styled.div`
  background: var(--surface-primary);
  color: var(--text-inverse-strong);
  padding: var(--spacing-xx-small) var(--spacing-x-small);
  opacity: 0.87;
  z-index: 99;
  border-radius: var(--spacing-xxx-small);

  position: fixed;
  bottom: var(--spacing-xx-small);
  left: var(--spacing-xx-small);

  a {
    color: var(--text-inverse-strong);
  }
`;

const ExitLink = styled(BaseLink)`
  font-weight: inherit;
  font-size: inherit;
  text-decoration: underline;
`;

export { PreviewBanner };
