import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Heading, HeadingLevel } from '@/components/common/MarkUp';

type HeaderItemProps = {
  text: string;
  padding: 'tight' | 'paddingBottom' | 'paddingBoth';
};

export const HeaderItem = ({ text, padding }: HeaderItemProps) => {
  return (
    <HeadingLevel>
      <StyledHeading size="label-md" padding={padding}>
        {text}
      </StyledHeading>
    </HeadingLevel>
  );
};

const StyledHeading = styled(Heading)<{
  padding: 'tight' | 'paddingBottom' | 'paddingBoth';
}>`
  color: var(--text-warm-subtle);
  padding: ${({ padding }) => {
    if (padding === 'paddingBottom')
      return css` 0 0 var(--space-component-md) 0;`;
    if (padding === 'paddingBoth') return css` var(--space-component-md) 0;`;
    return '0;';
  }};
`;
