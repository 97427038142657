import type {
  ButtonLinkType,
  CombinedButtonProps,
} from '@/components/common/Buttons/shared';

export const isAnchor = (
  props: CombinedButtonProps,
): props is ButtonLinkType => {
  return 'href' in props;
};
