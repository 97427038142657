import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import clsx from 'clsx';

import { useScrollListener } from '@/lib/useScrollListener';

import type { ReactNode } from 'react';

interface AnimateProps {
  settings?: {
    effect?: 'fade-in';
    scrollOffset?: number;
    transitionSpeed?: number;
    runOnce?: boolean;
  };
  className?: string;
  children: ReactNode;
}

export const Animate = ({
  children,
  settings: {
    effect = 'fade-in',
    scrollOffset = 40,
    transitionSpeed = 0.5,
    runOnce = true,
  } = {
    effect: 'fade-in',
    scrollOffset: 40,
    transitionSpeed: 0.5,
    runOnce: true,
  },
  className,
  ...props
}: AnimateProps) => {
  const [containerRef, isContainerVisible] = useScrollListener({
    root: null,
    rootMargin: `0px`,
    runOnce,
  });

  const [animationClassName, setAnimationClassName] = useState('');

  useEffect(() => {
    setAnimationClassName(isContainerVisible ? effect : '');
  }, [isContainerVisible, effect]);

  return (
    <AnimateContainer
      scrollOffset={scrollOffset}
      transitionSpeed={transitionSpeed}
      className={clsx(className, animationClassName)}
      ref={containerRef}
      {...props}
    >
      {children}
    </AnimateContainer>
  );
};
const AnimateContainer = styled.div<{
  scrollOffset: number;
  transitionSpeed: number;
}>`
  opacity: 0;
  top: ${({ scrollOffset }) => `${scrollOffset}px`};
  position: relative;

  &.fade-in {
    opacity: 1;
    top: 0;
    transition-duration: ${({ transitionSpeed }) => `${transitionSpeed}s`};
    transition-behavior: normal;
    transition-timing-function: ease-in;
    transition-delay: 0s;
    transition-property: top, opacity;
  }
`;
