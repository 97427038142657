import { AudienceSwitcher } from '@/components/layout/NavigationBar/AudienceSwitcher';
import { DesktopNav } from '@/components/layout/NavigationBar/DesktopNav';
import { MobileNav } from '@/components/layout/NavigationBar/MobileNav';
import { DesktopNav_ex } from '@/components/layout/NewNavigationBar/DesktopNav_ex';
import { MobileNav_ex } from '@/components/layout/NewNavigationBar/MobileNav_ex';

import type { SanityAudienceType, SanityNavigationType } from '@/types/sanity';
import type { Navigation_exType, SiteSettings } from '@/types/shared';

export interface NavigationBarProps {
  navigation: SanityNavigationType;
  navigation_ex: Navigation_exType;
  audiences?: SanityAudienceType[];
  hideCTA?: boolean;
  isSticky?: boolean;
  siteSettings: SiteSettings;
}

export const NavigationBar = ({
  navigation,
  navigation_ex,
  audiences,
  siteSettings,
  isSticky = true,
}: NavigationBarProps) => {
  const newNavigation = !!siteSettings?.newNavigation;

  if (newNavigation) {
    return (
      <>
        {audiences && (
          <AudienceSwitcher
            audiences={audiences}
            siteSettings={siteSettings}
            activeAudience={navigation_ex?.audience}
          />
        )}
        {navigation_ex && navigation_ex?.navItems?.length > 0 && (
          <>
            <DesktopNav_ex
              navigation={navigation_ex}
              isAudienceSwitcherEnabled={
                siteSettings?.isAudienceSwitcherEnabled
              }
              isSticky={isSticky}
            />
            <MobileNav_ex
              navigation={navigation_ex}
              isAudienceSwitcherEnabled={
                siteSettings?.isAudienceSwitcherEnabled
              }
              isSticky={isSticky}
            />
          </>
        )}
      </>
    );
  }

  return (
    <>
      <DesktopNav navigation={navigation} />
      <MobileNav navigation={navigation} />
    </>
  );
};
