import type { LocaleTranslations } from '@/types/shared';

export const getAlternateTags = (
  localeTranslations: LocaleTranslations,
  path: string,
) => {
  const validLocales = ['en-GB', 'en-US'];
  const baseDefaultLocale = validLocales[0];
  const host = 'https://www.multiverse.io/';
  const alternateTags = [];
  let defaultHref = '';

  if (typeof localeTranslations === 'boolean') {
    validLocales.forEach((locale) => {
      alternateTags.push({
        href: host + locale + path,
        lang: locale,
      });
    });
    defaultHref = host + baseDefaultLocale + path;
  }

  const pathName = (type, slug) => {
    if (slug === '/') return '';
    switch (type) {
      case 'page':
        return `/${slug}`;
      case 'infoPage':
        return `/info/${slug}`;
      case 'blogPost':
        return `/blog/${slug}`;
      case 'blogAuthor':
        return `/blog/author/${slug}`;
      case 'blogCategory':
        return `/blog/category/${slug}`;
      case 'hubspotForm':
        return `/${slug}`;
      default:
        return '';
    }
  };

  if (Array.isArray(localeTranslations)) {
    const defaultUrl = () => {
      const defaultPage =
        localeTranslations.find(
          (locale) => locale._lang === baseDefaultLocale,
        ) || localeTranslations[0];
      const { _lang, slug, _type } = defaultPage;

      return host + _lang + pathName(_type, slug.current);
    };

    localeTranslations.forEach((locale) => {
      alternateTags.push({
        href: host + locale._lang + pathName(locale._type, locale.slug.current),
        lang: locale._lang,
      });
    });
    defaultHref = defaultUrl();
  }

  return { defaultHref, alternateTags };
};
