import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Link from 'next/link';

import { screen } from '@/components/common/breakpoints';
import { zIndex } from '@/components/common/z-index';
import { mobileBreakpoint } from '@/components/layout/NewNavigationBar/DesktopNavLinks';
import { bodyMedium, labelMedium } from '@/styles/typography';

import type { SanityAudienceType } from '@/types/sanity';
import type { SiteSettings } from '@/types/shared';

export interface AudienceSwitcherProps {
  audiences: SanityAudienceType[];
  siteSettings: SiteSettings;
  activeAudience?: SanityAudienceType;
}

export const AudienceSwitcher = ({
  audiences,
  siteSettings,
  activeAudience,
}: AudienceSwitcherProps) => {
  const addCookie = (cookieValue: string) => {
    document.cookie = `AudienceType=${cookieValue}; path=/`;
  };

  return (
    <SwitcherBar>
      <Container>
        <Links>
          {audiences.map((audience) => (
            <AudienceLink
              href={audience.tabLink}
              key={audience.name}
              onClick={() => addCookie(audience.name?.toLowerCase())}
              active={
                audience.name.toLowerCase() ===
                (activeAudience?.name.toLowerCase() ??
                  audiences[0].name.toLowerCase())
              }
            >
              <span>{audience.displayName}</span>
            </AudienceLink>
          ))}
        </Links>
        {siteSettings?.banner?.enabled && (
          <BannerLink href={siteSettings.banner.url}>
            <span>{siteSettings.banner.copy}</span>
          </BannerLink>
        )}
      </Container>
    </SwitcherBar>
  );
};

const SwitcherBar = styled.div`
  background: var(--surface-inverse-strong);
  padding: 0 16px 0 24px;
  z-index: ${zIndex.desktopNav};
  position: relative;

  @media (min-width: ${mobileBreakpoint}px) {
    padding: 0 var(--space-page-sm);
  }
`;

const Container = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;

  max-width: var(--navigation_ex-desktop-max-width);
  margin: auto;

  ${screen.md} {
    justify-content: space-between;
  }
`;

const Links = styled.div`
  display: flex;
  gap: var(--space-component-xl);
`;

const linkCommon = css`
  color: var(--text-inverse-warm-base);
  text-decoration: none;

  padding-block: var(--space-component-md);

  &:hover {
    text-decoration: underline;
    text-underline-offset: 2px;
  }

  &:focus-visible {
    text-decoration: none;
    outline: unset;

    span {
      outline: 2px solid var(--border-inverse-action);
      outline-offset: var(--spacing-50);
      border-radius: var(--radius-s);
    }
  }
`;

const AudienceLink = styled(Link, { shouldForwardProp: isPropValid })<{
  active: boolean;
}>`
  ${labelMedium};
  ${linkCommon};

  position: relative;

  ${({ active }) =>
    active &&
    css`
      color: var(--text-eclipse);

      &:hover {
        text-decoration: unset;
      }

      &::after {
        content: '';

        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3px;
        border-radius: var(--radius-xxs) var(--radius-xxs) 0 0;

        background-color: currentColor;
      }
    `}
`;

const BannerLink = styled(Link)`
  ${bodyMedium};
  ${linkCommon};

  display: none;

  ${screen.md} {
    display: block;
  }
`;
