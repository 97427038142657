import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { screen } from '@/components/common/breakpoints';

import type { Responsive } from '@/types/helpers';
import type {
  blockSpacingOptions,
  componentSpacingOptions,
  Spacing,
} from '@/types/measures';

type ResponsiveSpacing = Responsive<Spacing>;

export interface StackProps {
  spacing?:
    | Spacing
    | ResponsiveSpacing
    | componentSpacingOptions
    | blockSpacingOptions;
}

const spacingDefaults = { base: '--spacing-medium' };

// Iterate over all breakpoints and return a css snippet if a value exists for the particular property
const getBreakpoints = (spacing: ResponsiveSpacing) => {
  const spacingWithDefault = { ...spacingDefaults, ...spacing };

  const styles = Object.entries(spacingWithDefault).map(
    ([breakpoint, value]) => {
      if (value !== 0) {
        if (breakpoint === 'base') {
          return `
            gap: var(${value});
          `;
        }

        return `
          ${screen[breakpoint]} {
            gap: var(${value});
          }`;
      }

      return `
      ${screen[breakpoint]} {
        gap: 0;
      }`;
    },
  );

  return styles.join(' ');
};

// As 'sizes' is a valid prop in HTML, it gets passed onto the Col div, even though it doesn't make sense here.
const shouldForwardProp = (propName: string) =>
  isPropValid(propName) && propName !== 'spacing';

export const Stack = styled('div', { shouldForwardProp })<StackProps>`
  width: 100%;

  display: flex;
  flex-direction: column;

  ${({ spacing = '--spacing-medium' }) => {
    if (typeof spacing === 'string')
      return css`
        gap: var(${spacing});
      `;
    if (spacing === 0) {
      return css`
        gap: 0;
      `;
    }

    return getBreakpoints(spacing);
  }}
`;
