export const zIndex = {
  skipLinks: 20,
  tooltipOverlay: 13,
  jobsFullScreenFilter: 11,
  navBar: 100,
  desktopNav: 110,
  desktopNavContainer: 105,
  desktopOverlay: 99,
  close: 9,
  jobsStickyScroll: 8,
};
