import { useMemo } from 'react';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';

import BaseLink from '@/components/common/BaseLink/BaseLink';
import { Paragraph } from '@/components/common/MarkUp';

export interface SubNavigationLinkProps {
  title: string;
  additionalText?: string;
  url: string;
  tabIndex?: number;
}

export const SubNavigationLink = ({
  title,
  additionalText,
  url,
  tabIndex,
}: SubNavigationLinkProps) => {
  const { asPath } = useRouter();
  const ariaCurrent = useMemo<'page' | null>(
    () => (url === asPath ? 'page' : null),
    [url, asPath],
  );

  return (
    <>
      <BaseLink
        href={url}
        aria-label={title}
        trackingTag={{ blockName: 'nav', blockAction: 'link' }}
        aria-current={ariaCurrent}
        tabIndex={tabIndex}
      >
        {title}
      </BaseLink>
      <LinkDescription size="body-lg">{additionalText}</LinkDescription>
    </>
  );
};

const LinkDescription = styled(Paragraph)`
  color: var(--text-strong);

  margin-top: var(--spacing-xx-small);
`;
