import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { PortableText as PortableTextComponent } from '@portabletext/react';

import { Animate } from '@/components/common/Animate';
import BaseLink from '@/components/common/BaseLink/BaseLink';
import { HeadingLevelContext } from '@/components/common/Heading/context';
import {
  BlockQuote,
  Heading,
  List,
  Paragraph,
} from '@/components/common/MarkUp';
import { TooltipV2 } from '@/components/common/TooltipV2';
import { blockUrlWithAudience } from '@/lib/utils';

import type { HeadingSanityBlockType } from '@/components/common/Heading';
import type { TypographySizeTypes } from '@/styles/typography';
import type { CreateStyled } from '@emotion/styled';
import type { ParsedUrlQuery } from 'querystring';

const PROGRAMMES_PATH = 'courses';

export const visuallyHiddenStyle = () => css`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
`;

export const VisuallyHidden = styled.span`
  ${visuallyHiddenStyle}
`;

interface destinationProps {
  target: string;
  isExternalLink: boolean;
  newTabText: string;
}

export const determineDestination = (
  href: string,
  isExternal: boolean | null = null,
): destinationProps => {
  const isExternalLink =
    isExternal ||
    (href && href.includes('platform.multiverse.io')) ||
    (href &&
      !href.includes('multiverse.io') &&
      !href.startsWith('/') &&
      !href.startsWith('#'));
  return {
    target: isExternalLink ? '_blank' : '_self',
    isExternalLink,
    newTabText: isExternalLink ? '(opens new window)' : '',
  };
};

export const transientOptions: Parameters<CreateStyled>[1] = {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
};

export const stringToId = (str: string): string => {
  return str.toLowerCase().replaceAll(' ', '-');
};

export const ConditionalAnimateWrapper = ({
  children,
  animate,
}): JSX.Element => {
  return animate?.enabled ? (
    <Animate {...animate}>{children}</Animate>
  ) : (
    children
  );
};

export const formatDate = (date: string) => {
  const formattedDate = new Date(`${date}T00:00`).toLocaleString('en-GB', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });
  return formattedDate;
};

const getHeading = (
  level,
  children,
  size = 'subheading-lg' as TypographySizeTypes,
) => (
  <HeadingLevelContext.Provider value={level}>
    <Heading size={size}>{children}</Heading>
  </HeadingLevelContext.Provider>
);

export const components = (audienceConfig?) => {
  return {
    block: {
      h1: ({ children }) => getHeading(1, children),
      h2: ({ children }) => getHeading(2, children),
      h3: ({ children }) => getHeading(3, children, 'subheading-md'),
      h4: ({ children }) => getHeading(4, children, 'subheading-sm'),
      h5: ({ children }) => getHeading(5, children, 'subheading-sm'),
      h6: ({ children }) => getHeading(6, children, 'subheading-sm'),
      blockquote: ({ children }) => (
        <BlockQuote>&quot;{children}&quot;</BlockQuote>
      ),
      normal: ({ children }) => (
        <Paragraph size="body-lg">{children}</Paragraph>
      ),
      heading: ({ children }) => (
        <Heading size="subheading-lg">{children}</Heading>
      ),
      'subheading-sm': ({ children }) => (
        <Paragraph size="subheading-sm">{children}</Paragraph>
      ),
      'body-small': ({ children }) => (
        <Paragraph size="body-lg">{children}</Paragraph>
      ),
      'headline-sm': ({ children }) => (
        <Heading size="heading-sm">{children}</Heading>
      ),
      'heading-sm': ({ children }) => (
        <Heading size="heading-sm">{children}</Heading>
      ),
    },
    marks: {
      highlight: ({ children }) => children,
      strong: ({ children }) => <b>{children}</b>,
      internalLink: ({ value, children }) => {
        let prefix = '';
        let postfix = '';

        switch (value?.item?._type) {
          case 'blogPost':
            prefix = '/blog';
            break;
          case 'programmeB2C': {
            const { audienceRef, b2cAudience } = value.item;
            const basePath = audienceRef ? audienceRef.basePath : '';

            postfix = b2cAudience ? `?b2cAudience=${b2cAudience}` : '';
            prefix = `${basePath}/${PROGRAMMES_PATH}`;
            break;
          }
          case 'programmeB2B': {
            const { audienceRef } = value.item;
            const basePath = audienceRef ? audienceRef.basePath : '';

            prefix = `${basePath}/${PROGRAMMES_PATH}`;
            break;
          }
          case 'infoPage': {
            const audienceRef = value?.item?.audienceRef;
            const basePath = audienceRef ? audienceRef.basePath : '';
            const infoPath = 'info';
            prefix = `${basePath}/${infoPath}`;
            break;
          }
          case 'caseStudy':
            prefix = '/case-studies';
            break;
          case 'page':
          default: {
            const audienceRef = value?.item?.audienceRef;
            prefix = audienceRef?.basePath ?? '';
            break;
          }
        }

        const href = `${prefix}/${value.item?.slug.current}${postfix}`;

        return <BaseLink href={href}>{children}</BaseLink>;
      },
      link: ({ value, children }) => {
        const { href, audienceRef, noAudienceToggle } = value;
        const { pageAudience, isAudienceSwitcherEnabled } =
          audienceConfig || {};

        const hrefWithAudience = blockUrlWithAudience(
          href,
          audienceRef,
          pageAudience,
          noAudienceToggle,
          isAudienceSwitcherEnabled,
        );

        const destination = determineDestination(hrefWithAudience);

        if (href) {
          return (
            <BaseLink
              href={hrefWithAudience}
              target={destination.target}
              rel={destination.isExternalLink ? 'external noopener' : null}
            >
              {children}
              {destination.isExternalLink && (
                <VisuallyHidden>{destination.newTabText}</VisuallyHidden>
              )}
            </BaseLink>
          );
        } else return <span>{children}</span>;
      },
      tooltip: ({ children, value }) => {
        if (!value.content) return <span>{children}</span>;

        return (
          <TooltipV2 key={value._key} mark={value}>
            {children}
          </TooltipV2>
        );
      },
    },
    list: ({ value, children }) => {
      if (value.listItem === 'number') {
        return (
          <List type="ol" size="subheading-sm">
            {children}
          </List>
        );
      } else {
        return <List size="subheading-sm">{children}</List>;
      }
    },
    listItem: ({ children }) => (
      <li>
        <span>{children}</span>
      </li>
    ),
    unknownMark: ({ children }) => children,
    unknownType: ({ children }) => children,
  };
};

export const PortableText = (props) => {
  return (
    <PortableTextComponent
      onMissingComponent={(message, options) => {
        // eslint-disable-next-line no-console
        console.error('PortableText: missing component', { message, options });
      }}
      components={{ ...components(props?.audienceConfig) }}
      {...props}
    />
  );
};

export const constructSlug = (params: ParsedUrlQuery): string => {
  return params.slug && Array.isArray(params.slug)
    ? params.slug.join('/')
    : '/';
};

export const desktopTitleStartPosition = (
  widthConstraint: 'full' | 'narrow',
  desktopAlignment: 'start' | 'center' | 'end',
) => {
  if (widthConstraint === 'full') {
    return 1;
  }
  switch (desktopAlignment) {
    case 'center':
      return 3;
    case 'end':
      return 5;
    case 'start':
    default:
      return 1;
  }
};

const generateRandomKey = (str: string): string => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0;
  }
  return hash.toString(16);
};

export const stringToHeading = (text: string): HeadingSanityBlockType => {
  return [
    {
      _key: generateRandomKey(text),
      _type: 'block',
      children: [
        {
          _type: 'span',
          text: text,
        },
      ],
    },
  ];
};
