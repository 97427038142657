import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Link from 'next/link';

import { isAnchor } from '@/components/common/Buttons/utils';
import { Icon } from '@/components/common/Icon';
import { determineDestination } from '@/components/common/utils';

import type {
  ButtonLinkType,
  ButtonSize,
  ButtonType,
  ButtonVariant,
  IconButtonProps,
} from '@/components/common/Buttons/shared';

export const IconOnlyButton = (props: IconButtonProps) => {
  const { variant = 'solid-light', icon, size = 'xl' } = props;

  if (isAnchor(props)) {
    const { 'aria-label': ariaLabel } = props;
    const destination =
      typeof props?.href === 'string' && determineDestination(props?.href);

    return (
      <CoreIconLinkButton
        aria-label={ariaLabel ? `${ariaLabel} ${destination.newTabText}` : null}
        rel={destination.isExternalLink ? 'external noopener' : null}
        variant={variant}
        size={size}
        {...props}
      >
        <IconContainer icon={icon} size={size} />
      </CoreIconLinkButton>
    );
  } else {
    return (
      <CoreIconButton variant={variant} size={size} {...props}>
        <IconContainer icon={icon} size={size} />
      </CoreIconButton>
    );
  }
};

const getButtonStyle = (variant: ButtonVariant) => {
  switch (variant) {
    case 'solid-dark':
      return css`
        background: var(--surface-subtle);

        svg {
          color: var(--icon-action);
        }

        &:hover {
          svg {
            color: var(--icon-action-dark);
          }
        }

        &:focus-visible {
          outline: 2px solid var(--border-inverse-action);
          outline-offset: 2px;
        }
      `;
    case 'flat-light':
      return css`
        background: var(--background-subtle);
        border: solid 1px var(--border-warm-base);
        svg {
          color: var(--icon-strong);
        }
        &:hover {
          background: var(--surface-warm-strong);
          svg {
            color: var(--icon-strong);
          }
        }

        &:focus-visible {
          outline: 2px solid var(--border-action);
          outline-offset: 2px;
        }
      `;
    case 'flat-dark':
      return css`
        background: var(--surface-inverse-base);
        border: 1px solid var(--border-inverse-base);

        svg {
          color: var(--icon-inverse-strong);
        }

        &:hover {
          background: var(--surface-inverse-strong);
        }

        &:focus-visible {
          outline: 2px solid var(--border-inverse-action);
          outline-offset: 2px;
        }
      `;

    default:
    case 'solid-light':
      return css`
        background: var(--surface-primary);

        &:hover {
          background: var(--surface-inverse-primary);
        }

        svg {
          color: var(--icon-inverse-strong);
        }

        &:focus-visible {
          outline: 2px solid var(--border-action);
          outline-offset: 2px;
        }
      `;
  }
};

const getSize = (size: ButtonSize) => {
  switch (size) {
    case 'md':
      return css`
        height: 40px;
        width: 40px;
      `;

    case 'lg':
      return css`
        height: 48px;
        width: 48px;
      `;

    default:
    case 'xl':
      return css`
        height: 56px;
        width: 56px;
      `;
  }
};

const coreStyling = css`
  background: unset;
  border: unset;
  cursor: pointer;
  padding: 0;

  border-radius: var(--radius-full);
  transition: all 0.2s ease;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  &.disabled,
  &:disabled,
  .slick-disabled & {
    opacity: var(--opacity-disabled);
    pointer-events: none;
    cursor: default;
  }

  &:active {
    transform: scale(0.96);
  }
`;

const CoreIconButton = styled.button<ButtonType>`
  ${coreStyling};

  ${(props) => getButtonStyle(props.variant)};
  ${(props) => getSize(props.size)};
`;

const CoreIconLinkButton = styled(Link)<ButtonLinkType>`
  ${coreStyling};

  ${(props) => getButtonStyle(props.variant)};
  ${(props) => getSize(props.size)};
`;

const iconSizeMap = {
  md: '20px',
  lg: '24px',
  xl: '28px',
} as const;

export const IconContainer = ({
  icon = 'ArrowRight',
  size = 'xl',
}: Pick<IconButtonProps, 'icon' | 'size'>) => {
  return <Icon icon={icon} size={iconSizeMap[size]} />;
};
