import { useEffect, useState } from 'react';

export const useMediaQuery = (query) => {
  const [hasReachedBreakpoint, setHasReachedBreakpoint] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    setHasReachedBreakpoint(media.matches);
    media.onchange = () => {
      setHasReachedBreakpoint(media.matches);
    };
  }, [query, setHasReachedBreakpoint]);

  return hasReachedBreakpoint;
};
