import { useMemo, useRef } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { HighlightAnnotation } from '@/components/common/Heading/HighlightAnnotation';
import { Heading } from '@/components/common/MarkUp';
import { PortableText } from '@/components/common/utils';
import { blocksToText } from '@/lib/sanityUtils';
import { typographyStyles } from '@/styles/typography';

import { Animation, HeadingContainer, HeadingItems } from './Animation';
import { HeadingContainerContext } from './context';

import type { TypographySizeTypes } from '@/styles/typography';
import type { SanityBlock, SanityKeyed } from '@/types/sanity';

const components = ({ animationInline = false }) => ({
  /*
    Remove wrapping p tag to stop `h1 > p`
    https://github.com/sanity-io/block-content-to-hyperscript/blob/master/src/serializers.js#L58
    Reintroduce div so that line breaks in the editor actually does something
  */
  block: ({ children }) => (animationInline ? <div>{children}</div> : children),
  marks: {
    strong: ({ children }) => <BoldSpan>{children}</BoldSpan>,
    highlightAnnotation: ({ children, value }) => (
      <HighlightAnnotation key={value._key} {...value}>
        {children}
      </HighlightAnnotation>
    ),
    animated: ({ children, value }) => (
      <Animation key={value._key} {...value}>
        {children}
      </Animation>
    ),
  },
  highlight: ({ children }) => children,
  unknownMark: ({ children }) => children,
  unknownType: ({ children }) => children,
});

const TextHolder = styled.span<{ animationInline?: boolean }>`
  position: relative;
  ${(props) =>
    props.animationInline &&
    css`
      display: inline-block;

      ${HeadingItems} {
        display: inline-block;
      }

      ${HeadingContainer} {
        white-space: nowrap;
      }
    `};
`;

const BoldSpan = styled.span`
  font-weight: 700;
`;

export interface HeadingSanityBlockType
  extends Array<SanityKeyed<SanityBlock>> {}

export interface HeadingProps {
  heading: HeadingSanityBlockType;
  size?: TypographySizeTypes;
  backgroundColour?: string;
  elementType?: 'paragraph' | 'span';
  animationInline?: boolean;
}

export const resolveSize = (heading, size) => {
  const { style } = heading[0];
  if (Object.keys(typographyStyles).includes(style)) return style;
  if (style === 'normal') return size || 'heading-lg';
  switch (style) {
    case 'small':
    case 'caption-sm':
      return 'label-md';
    case 'small-v2':
    case 'medium':
    case 'subheadline-bold':
    case 'subheadline':
      return 'subheading-lg';
    case 'heading-sm-v2':
    case 'headline-sm':
      return 'heading-sm';
    case 'heading-md-v2':
    case 'headline-md':
      return 'heading-md';
    case 'medium-v2':
    case 'heading-lg-v2':
    case 'large-v2':
    case 'headline-lg-v2':
    case 'headline-lg':
      return 'heading-lg';
    default:
      return 'heading-lg';
  }
};

export const RichHeading = ({
  heading,
  size,
  elementType,
  animationInline,
}: HeadingProps) => {
  const ref = useRef(null);
  const headingSize = resolveSize(heading, size);

  const providerValue = useMemo(() => ({ containerRef: ref }), [ref]);
  const isAnimationPresent =
    heading instanceof Array &&
    heading.some((line) =>
      line.markDefs?.some((mark) => mark._type === 'animated'),
    );

  const componentsMemo = useMemo(
    () => components({ animationInline }),
    [animationInline],
  );

  return (
    <HeadingContainerContext.Provider value={providerValue}>
      <Heading
        elementType={elementType}
        size={headingSize}
        aria-label={isAnimationPresent ? blocksToText(heading) : null}
      >
        <TextHolder
          ref={ref}
          aria-hidden={isAnimationPresent ? 'true' : null}
          animationInline={animationInline}
        >
          <PortableText value={heading} components={componentsMemo} />
        </TextHolder>
      </Heading>
    </HeadingContainerContext.Provider>
  );
};
