import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useKeyboardEvent, useMeasure } from '@react-hookz/web';
import { m } from 'framer-motion';
import Link from 'next/link';
import FocusLock from 'react-focus-lock';

import { screen } from '@/components/common/breakpoints';
import { Button } from '@/components/common/Buttons';
import { zIndex } from '@/components/common/z-index';
import { MultiverseLogo } from '@/components/layout/MultiverseLogo';
import { DesktopNavLinks } from '@/components/layout/NavigationBar/DesktopNavLinks';
import { SubNavigation } from '@/components/layout/NavigationBar/SubNavigation';

import { colourVariants, naiveTransparentize } from './common';

import type { SanityNavigationType } from '@/types/sanity';

interface DesktopNavProps {
  navigation: SanityNavigationType;
}

export const DesktopNav = ({ navigation }: DesktopNavProps) => {
  const [activeItem, setActiveItem] = useState(null);
  const [subNavHeight, setSubNavHeight] = useState(0);

  const isActive = Boolean(activeItem);
  const activeVariant = isActive ? 'open' : 'close';

  const activeColours = colourVariants[activeVariant];

  const [pillMeasures, pillRef] = useMeasure<HTMLDivElement>();

  const closeNav = () => {
    setActiveItem(null);
    setSubNavHeight(0);
  };

  useKeyboardEvent('Escape', () => {
    closeNav();
  });

  return (
    <GlobalNav>
      <FocusLock disabled={!isActive} autoFocus={false}>
        <Container ref={pillRef} onPointerLeave={() => closeNav()}>
          <Pill
            animate={activeVariant}
            initial="close"
            variants={{
              open: {
                backgroundColor: naiveTransparentize(
                  activeColours.pill.colour.value,
                  1,
                ),
                filter: `drop-shadow(0px 4px 54px rgba(0, 0, 0, 0 ))`,
              },
              close: {
                backgroundColor: naiveTransparentize(
                  activeColours.pill.colour.value,
                  0.3,
                ),
                filter: `drop-shadow(0px 4px 54px rgba(0, 0, 0, 0.2))`,
              },
            }}
          >
            <LogoLink
              href="/"
              aria-label="Multiverse home"
              animate={activeVariant}
              initial="close"
              variants={{
                open: {
                  color: activeColours.logo.colour.value,
                },
                close: {
                  color: activeColours.logo.colour.value,
                },
              }}
              tabIndex={isActive ? -1 : 0}
            >
              <MultiverseLogo />
            </LogoLink>
            <Nav
              aria-label="main menu"
              id="subnavigation-list"
              animate={activeVariant}
              initial="close"
              variants={{
                open: {
                  color: activeColours.text.colour.value,
                },
                close: {
                  color: activeColours.text.colour.value,
                },
              }}
            >
              <DesktopNavLinks
                activeItem={activeItem}
                setActiveItem={setActiveItem}
                items={navigation.navItems}
              />
            </Nav>

            <Button
              icon="Email"
              iconPosition="right"
              variant={isActive ? 'solid-light' : 'solid-dark'}
              href={navigation.primaryCTA.buttonUrl}
              aria-label={navigation.primaryCTA.buttonAccessibleLabel}
              tabIndex={isActive ? -1 : 0}
            >
              {navigation.primaryCTA.buttonText}
            </Button>
          </Pill>

          <SubNavPosition>
            <SubNavContainer
              animate={activeVariant}
              transition={{ duration: 0.3 }}
              initial="close"
              variants={{
                open: {
                  WebkitClipPath: `xywh(0 0 100% 100% round var(--spacing-800))`,
                  clipPath: `xywh(0 0 100% 100% round var(--spacing-800))`,
                  opacity: 1,
                },
                close: {
                  WebkitClipPath: `xywh(24px 24px ${pillMeasures?.width ?? 0}px ${pillMeasures?.height ?? 0}px round var(--spacing-500))`,
                  clipPath: `xywh(24px 24px ${pillMeasures?.width ?? 0}px ${pillMeasures?.height ?? 0}px round var(--spacing-500))`,
                  opacity: 0,
                },
              }}
            >
              <SubNavSizer
                animate={{ height: subNavHeight }}
                transition={{ type: 'tween', ease: 'easeInOut', duration: 0.3 }}
              >
                {navigation?.navItems?.map((item) => (
                  <SubNavPanel
                    key={item._key}
                    isOpen={activeItem === item._key}
                    onHeightChange={(height) => {
                      if (activeItem === item._key) setSubNavHeight(height);
                    }}
                  >
                    <SubNavigation
                      columns={{
                        columnOne: item.columnOne,
                        columnTwo: item.columnTwo,
                        columnThree: item.columnThree,
                      }}
                      isOpen={isActive}
                      title={item.title}
                    />
                  </SubNavPanel>
                ))}
              </SubNavSizer>
            </SubNavContainer>
          </SubNavPosition>
        </Container>
      </FocusLock>
      <Overlay
        animate={activeVariant}
        initial="close"
        variants={{
          open: { opacity: 1, display: 'block' },
          close: { opacity: 0, transitionEnd: { display: 'none' } },
        }}
      />
    </GlobalNav>
  );
};

const SubNavPanel = ({ onHeightChange, isOpen, children }) => {
  const [measures, ref] = useMeasure<HTMLDivElement>();

  useEffect(() => {
    if (measures && isOpen && onHeightChange) {
      onHeightChange(measures.height);
    }
  }, [isOpen, measures, onHeightChange]);

  return (
    <SubnavItem
      ref={ref}
      initial="hidden"
      animate={isOpen ? 'show' : 'hidden'}
      variants={{
        show: {
          opacity: 1,
          visibility: 'visible',
        },
        hidden: {
          opacity: 0,
          transitionEnd: {
            visibility: 'hidden',
          },
        },
      }}
      transition={{ duration: 0.1 }}
    >
      {children}
    </SubnavItem>
  );
};

const GlobalNav = styled.header`
  display: none;

  ${screen.md} {
    display: block;
    position: sticky;
    top: 0;

    margin: 0 auto calc(var(--full-navbar-height) * -1);
    min-width: var(--mobile-min-width);
    max-width: var(--desktop-max-width);

    padding: var(--navbar-top) calc(var(--section-gutter) - var(--spacing-400))
      0;

    z-index: ${zIndex.navBar};
  }
`;

const Container = styled(m.div)`
  display: flex;

  justify-content: space-between;
  align-items: center;

  position: relative;
  z-index: ${zIndex.desktopNav};
`;

const Pill = styled(m.div)`
  backdrop-filter: blur(20px);

  padding-block: var(--spacing-150);
  padding-inline: var(--spacing-400) var(--spacing-150);
  border-radius: var(--spacing-500);
  width: 100%;

  display: flex;
  align-items: center;

  position: relative;
`;

const Nav = styled(m.nav)`
  margin-left: var(--spacing-1200);
  margin-right: auto;

  &:hover {
    &:after {
      opacity: 1;
    }
  }
`;

const LogoLink = styled(m(Link))`
  max-width: var(--spacing-1800);
  width: 100%;
  display: block;

  svg {
    width: 100%;
    display: block;
  }

  @media (forced-colors: active) and (prefers-color-scheme: dark) {
    svg path {
      fill: white !important;
    }
  }
`;

const SubNavContainer = styled(m.div)`
  overflow: hidden;
  background-color: var(--background-subtle);

  will-change: clip-path, background-color;

  padding-top: var(--spacing-1200);
  border-radius: var(--spacing-800);
`;

const SubNavSizer = styled(m.div)`
  will-change: height;
`;

const SubNavPosition = styled.div`
  width: calc(100% + 48px);
  height: auto;
  position: absolute;
  left: -24px;
  top: -24px;

  filter: drop-shadow(0px 4px 54px rgb(0, 0, 0, 20%));

  z-index: -1;
`;

const SubnavItem = styled(m.div)`
  overflow: hidden;
  position: absolute;
  width: 100%;
`;

const Overlay = styled(m.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  z-index: ${zIndex.desktopOverlay};

  background-color: color-mix(in srgb, var(--meteor-600) 80%, transparent);
  background-blend-mode: multiply;
  backdrop-filter: blur(5px);

  pointer-events: none;
`;
