import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Link from 'next/link';

import { Icon } from '@/components/common/Icon';
import { Heading, HeadingLevel, Paragraph } from '@/components/common/MarkUp';

export interface MenuItemProps {
  title: string;
  url: string;
  tier: number;
  description?: string;
}

export const MenuItem = ({ title, description, url, tier }: MenuItemProps) => {
  const getSize = (tier: number) => {
    if (tier === 2) return 'subheading-sm';
    if (tier === 3) return 'body-lg';

    return 'heading-sm';
  };

  return (
    <Container href={url}>
      <HeadingLevel>
        <TitleSection>
          <Title
            size={getSize(tier)}
            fontWeight={tier === 1 ? 'medium' : 'regular'}
          >
            {title}
          </Title>
          <Arrow
            icon="ArrowRight"
            colour="--icon-action"
            size={tier === 3 ? '12px' : '16px'}
          />
        </TitleSection>
      </HeadingLevel>
      {description && <Description size="body-md">{description}</Description>}
    </Container>
  );
};

const TitleSection = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
  outline: 2px solid transparent;
`;

const Title = styled(Heading)`
  padding: var(--spacing-x-small) 6px var(--spacing-x-small) 0;
`;

const Arrow = styled(Icon)<{ size: string }>`
  ${({ size }) =>
    size === '12px' &&
    css`
      position: relative;
      top: -3px;
    `}
  display: none;
`;

const Description = styled(Paragraph)`
  color: var(--text-warm-base);
`;

const Container = styled(Link)`
  text-decoration: none;
  color: unset;

  :hover {
    ${Title} {
      color: var(--text-action);
      text-decoration: underline;
      text-decoration-thickness: 1px;
      text-decoration-color: var(--border-warm-base);
      text-underline-offset: 3px;
    }
    ${Arrow} {
      display: block;
    }
    ${Description} {
      color: var(--text-strong);
    }
  }

  :focus-visible {
    outline: none;
    ${TitleSection} {
      ::before {
        content: '';
        position: absolute;
        top: 2px;
        bottom: 2px;
        left: -4px;
        right: -4px;
        border: 2px solid var(--border-action);
        border-radius: 16px;
        pointer-events: none;
        z-index: 1;
      }
    }
  }

  :active {
    ${TitleSection} {
      text-decoration: underline;
      text-decoration-thickness: 1px;
      text-decoration-color: var(--border-warm-base);
    }
  }
`;
