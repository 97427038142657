import { useCallback } from 'react';
import styled from '@emotion/styled';
import clsx from 'clsx';
import { m } from 'framer-motion';
import Link from 'next/link';
import { useRouter } from 'next/router';

import {
  determineDestination,
  stringToId,
  VisuallyHidden,
} from '@/components/common/utils';
import { labelLarge } from '@/styles/typography';

import type { SanityNavigationType } from '@/types/sanity';
import type { Dispatch, SetStateAction } from 'react';

interface NavigationLinkProps {
  href: string;
  title: string;
  id: string;
  isActive?: boolean;
  isOtherActive: boolean;
  onOpen: (key: string) => void;
  onClose?: () => void;
  index: number;
}

const NavigationLink = ({
  href,
  title,
  id,
  onOpen,
  onClose,
  isActive,
  isOtherActive,
  index,
}: NavigationLinkProps) => {
  const destination = determineDestination(href, false);
  const { asPath } = useRouter();

  const ariaCurrent = href === asPath ? 'page' : null;
  const titleString = title ? `-${stringToId(title)}` : null;

  const onA11yClick = useCallback(() => {
    if (isActive && onClose) {
      onClose();
    } else {
      onOpen(id);
    }
  }, [id, isActive, onClose, onOpen]);

  const handleOnPointerEnter = (id: string) => {
    if (window.desktopNavTopLinkId !== id) {
      onOpen(id);
      window.desktopNavTopLinkId = id;
    }
  };

  return (
    <>
      <NavLink
        href={href}
        onPointerEnter={() => handleOnPointerEnter(id)}
        onPointerLeave={() => (window.desktopNavTopLinkId = null)}
        className={clsx('tracking-nav__link', { isActive })}
        target={destination.target}
        aria-label={title}
        aria-current={ariaCurrent}
        style={{ gridColumn: index + 1 }}
        tabIndex={isOtherActive ? -1 : 0}
        onClick={() => (window.desktopNavTopLinkId = id)}
      >
        {title}
      </NavLink>

      <VisuallyHidden>
        <AccessibilityButton
          type="button"
          aria-expanded={isActive}
          aria-controls={`sub-navigation${titleString}`}
          aria-label={`Open ${title} sub-navigation`}
          tabIndex={isOtherActive ? -1 : 0}
          onClick={() => onA11yClick()}
          onKeyDown={(event) => {
            if (event.code === 'Space') {
              event.preventDefault();
              onA11yClick();
            }
          }}
        />
      </VisuallyHidden>

      {isActive && (
        <Indicator style={{ gridColumn: index + 1 }} layoutId="nav-indicator" />
      )}
    </>
  );
};

interface DesktopNavListProps {
  items: SanityNavigationType['navItems'];
  activeItem: string | null;
  setActiveItem: Dispatch<SetStateAction<string | null>>;
}

export const DesktopNavLinks = ({
  items,
  setActiveItem,
  activeItem,
}: DesktopNavListProps) => {
  const onOpen = (id) => {
    setActiveItem(id);
  };

  const onClose = () => {
    setActiveItem(null);
  };

  return (
    <NavList>
      {items.map(({ title, url, _key }, index) => (
        <NavigationLink
          key={_key}
          onOpen={onOpen}
          onClose={onClose}
          id={_key}
          href={url}
          title={title}
          index={index}
          isActive={activeItem === _key}
          isOtherActive={activeItem && activeItem !== _key}
        />
      ))}
    </NavList>
  );
};

const NavList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, max-content);
  grid-template-rows: 1fr;
`;

const NavLink = styled(Link)`
  ${labelLarge}
  color: inherit;
  padding: var(--spacing-100) var(--spacing-150);
  ${labelLarge}
  text-decoration: none;
  position: relative;
  z-index: 1;
  grid-row: 1;
  white-space: nowrap;

  &.isActive {
    transition: color 0.45s;
    color: var(--text-inverse-strong);
  }

  &:focus-visible {
    border-radius: var(--radius-m);
    outline: 2px solid var(--border-action);
  }
`;

const Indicator = styled(m.div)`
  background: var(--surface-primary);
  border-radius: var(--radius-full);

  width: 100%;
  height: 100%;
  grid-row: 1;
`;

const AccessibilityButton = styled.button`
  all: unset;
  appearance: none;
  pointer-events: none;

  opacity: 0;

  width: 10px;
  height: 10px;

  &:focus {
    pointer-events: auto;
    opacity: 1;
  }
`;
