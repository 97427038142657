import { useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useKeyboardEvent, useResizeObserver } from '@react-hookz/web';
import { m } from 'framer-motion';
import Link from 'next/link';
import FocusLock from 'react-focus-lock';
import { RemoveScroll } from 'react-remove-scroll';

import { screen } from '@/components/common/breakpoints';
import { Button, IconOnlyButton } from '@/components/common/Buttons';
import { Icon } from '@/components/common/Icon';
import { Paragraph } from '@/components/common/MarkUp';
import { zIndex } from '@/components/common/z-index';
import { MultiverseLogo } from '@/components/layout/MultiverseLogo';
import { SubNavigation } from '@/components/layout/NavigationBar/SubNavigation';
import { colourTokens } from '@/styles/colours';

import { colourVariants, naiveTransparentize } from './common';

import type { SanityNavigationType } from '@/types/sanity';

interface MobileNavProps {
  navigation: SanityNavigationType;
}

export const MobileNav = ({ navigation }: MobileNavProps) => {
  const globalRef = useRef<HTMLDivElement>();
  const pillRef = useRef();

  const [navOpen, setNavOpen] = useState(false);
  const [itemOpen, setItemOpen] = useState<string | null>(
    navigation.navItems[0].title,
  );

  const [pillMeasures, setPillMeasures] = useState(undefined);

  const activeVariant = navOpen ? 'open' : 'close';

  const activeColours = colourVariants[activeVariant];

  useResizeObserver(pillRef, (entries) => {
    const { width, height, top, left, x, y } =
      entries.target.getBoundingClientRect();

    setPillMeasures({ width, height, top, left, x, y });
  });

  const toggleNav = () => {
    setNavOpen((value) => {
      if (!value) {
        // Opening

        const { top } = globalRef.current.getBoundingClientRect();
        globalRef.current.style.setProperty('--offset-top', `${top}px`);
      }

      return !value;
    });
  };

  const closeNav = () => {
    setNavOpen(false);
  };

  useKeyboardEvent('Escape', () => {
    closeNav();
  });

  return (
    <GlobalNav ref={globalRef}>
      <Container>
        <Pill
          ref={pillRef}
          animate={activeVariant}
          initial="close"
          variants={{
            open: {
              backgroundColor: naiveTransparentize(
                activeColours.pill.colour.value,
                1,
              ),
              filter: `drop-shadow(0px 4px 54px rgba(0, 0, 0, 0 ))`,
            },
            close: {
              backgroundColor: naiveTransparentize(
                activeColours.pill.colour.value,
                0.3,
              ),
              filter: `drop-shadow(0px 4px 54px rgba(0, 0, 0, 0.2))`,
            },
          }}
        >
          <LogoLink
            href="/"
            aria-label="Multiverse home"
            animate={activeVariant}
            initial="close"
            variants={{
              open: {
                color: activeColours.logo.colour.value,
              },
              close: {
                color: activeColours.logo.colour.value,
              },
            }}
          >
            <MultiverseLogo />
          </LogoLink>
          <IconOnlyButton
            variant={navOpen ? 'solid-light' : 'solid-dark'}
            size="md"
            icon="NavigationMenu"
            onClick={() => toggleNav()}
            aria-label={navOpen ? 'Close Navigation' : 'Open Navigation'}
          />
        </Pill>

        <RemoveScroll forwardProps enabled={navOpen}>
          <SubNavContainer
            animate={activeVariant}
            initial="close"
            variants={{
              open: {
                clipPath: `xywh(0 0 100% 100% round 0px)`,
                opacity: 1,
                visibility: 'visible',
              },
              close: {
                clipPath: `xywh(${pillMeasures?.x ?? 0}px 56px ${pillMeasures?.width ?? 0}px ${pillMeasures?.height ?? 0}px round 32px)`,
                opacity: 0,
                transitionEnd: {
                  visibility: 'hidden',
                },
              },
            }}
            role="dialog"
            aria-modal={navOpen}
            aria-label="Site Navigation"
          >
            <FocusLock>
              {navigation?.navItems?.map((item) => (
                <RevealerItem key={item._key}>
                  <RevealerControl
                    onClick={() =>
                      setItemOpen((value) =>
                        value === item.title ? null : item.title,
                      )
                    }
                  >
                    <Paragraph size="subheading-sm">{item.title}</Paragraph>
                    <m.div
                      animate={itemOpen === item.title ? 'open' : 'close'}
                      initial="close"
                      aria-hidden
                      variants={{
                        open: {
                          rotate: '180deg',
                          color: colourTokens.text.action.colour.value,
                        },
                        close: {
                          rotate: '0deg',
                          color: colourTokens.text.coolSubtle.colour.value,
                        },
                      }}
                    >
                      <Icon icon="ChevronDown" />
                    </m.div>
                  </RevealerControl>
                  <RevealerPanel
                    animate={itemOpen === item.title ? 'open' : 'close'}
                    initial="close"
                    variants={{
                      open: {
                        height: 'auto',
                      },
                      close: {
                        height: 0,
                      },
                    }}
                  >
                    <SubNavigation
                      columns={{
                        columnOne: item.columnOne,
                        columnTwo: item.columnTwo,
                        columnThree: item.columnThree,
                      }}
                      isOpen={itemOpen === item.title && navOpen}
                      title={item.title}
                    />
                  </RevealerPanel>
                </RevealerItem>
              ))}
              <CTAContainer
                animate={activeVariant}
                variants={{
                  open: {
                    y: '0%',
                  },
                  close: {
                    y: '100%',
                  },
                }}
                transition={{ duration: 0.3, delay: 0.2 }}
              >
                <Button
                  icon="Email"
                  iconPosition="right"
                  variant="solid-light"
                  href={navigation.primaryCTA.buttonUrl}
                  aria-label={navigation.primaryCTA.buttonAccessibleLabel}
                >
                  {navigation.primaryCTA.buttonText}
                </Button>
              </CTAContainer>
            </FocusLock>
          </SubNavContainer>
        </RemoveScroll>
      </Container>
    </GlobalNav>
  );
};

const GlobalNav = styled.header`
  position: sticky;
  top: 0;

  margin: 0 auto calc(var(--full-navbar-height) * -1);
  min-width: var(--mobile-min-width);
  max-width: var(--desktop-max-width);
  padding: var(--spacing-medium)
    calc(var(--section-gutter) - var(--spacing-150)) 0;

  z-index: ${zIndex.navBar};

  ${screen.md} {
    display: none;
  }
`;

const Container = styled(m.div)`
  display: flex;

  z-index: ${zIndex.desktopNav};
`;

const Pill = styled(m.div)`
  backdrop-filter: blur(10px);

  padding-block: var(--spacing-100);
  padding-inline: var(--spacing-200) var(--spacing-100);

  border-radius: var(--spacing-400);
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  position: relative;
`;

const LogoLink = styled(m(Link))`
  max-width: var(--spacing-1600);
  width: 100%;
  display: block;

  svg {
    display: block;
  }
`;

const SubNavContainer = styled(m.div)`
  width: 100%;
  height: calc(100vh - var(--offset-top, 0));
  position: absolute;
  left: 0;
  top: 0;

  padding: var(--spacing-1400) var(--section-gutter)
    calc(100lvh - 100svh + var(--spacing-1000));

  background-color: var(--background-subtle);

  will-change: clip-path;

  overflow: auto;
  z-index: -1;
`;

const RevealerItem = styled.div`
  border-radius: var(--spacing-400);

  background-color: var(--surface-primary-subtle);

  & + & {
    margin-top: var(--spacing-200);
  }
`;

const RevealerControl = styled.button`
  all: unset;
  appearance: none;
  box-sizing: border-box;
  border-radius: var(--radius-xl);

  display: flex;
  justify-content: space-between;
  align-items: center;

  cursor: pointer;
  width: 100%;

  padding: var(--spacing-250) var(--spacing-300);

  &:focus-visible {
    outline: 2px solid var(--border-action);
    outline-offset: -4px;
  }
`;

const RevealerPanel = styled(m.div)`
  overflow: hidden;

  > * {
    padding: var(--spacing-50) var(--spacing-300) var(--spacing-250);
  }
`;

const CTAContainer = styled(m.div)`
  position: fixed;
  bottom: 0;
  left: 0;

  width: 100%;
  padding: var(--spacing-150);

  background-color: var(--background-warm-base);

  border-radius: var(--spacing-400) var(--spacing-400) 0 0;

  display: flex;
  justify-content: center;
`;
