import { colourTokens } from '@/styles/colours';

export const colourVariants = {
  close: {
    pill: colourTokens.surface.inverseStrong,
    logo: colourTokens.surface.subtle,
    text: colourTokens.text.inverseStrong,
    cta: 'inverse-subtle',
  },
  open: {
    pill: colourTokens.background.warmBase,
    logo: colourTokens.decorative.ultraviolet,
    text: colourTokens.text.strong,
    cta: 'white',
  },
} as const;

export const naiveTransparentize = (colour: string, opacity: number) => {
  const str = colour.replace(/^rgb\(|\)$/g, '');

  return `rgba(${str}, ${opacity})`;
};
