import { useCallback, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import clsx from 'clsx';

import { HighlightPlaceholder } from '@/components/common/Heading/HighlightPlaceholder';

type Word = {
  _key: string;
  _type: 'combination';
  decorativeColour: string;
  word: string;
};
type HeadingTextSliderProps = {
  _key: string;
  children: string[];
  words: Word[];
  firstWordColour: string;
};

const Animation = ({
  _key,
  children,
  words,
  firstWordColour,
}: HeadingTextSliderProps) => {
  const wordsCombined: Word[] = [
    {
      _key,
      _type: 'combination',
      decorativeColour: firstWordColour,
      word: children[0],
    },
    ...words,
  ];

  const fadeTranisitionTime = 3000;
  const maxCycles = 3;
  const totalItems = wordsCombined.length;

  const [activeSlide, setActiveSlide] = useState(1);
  const cycle = useRef(1);

  const cycleWords = useCallback(() => {
    const timeout = setTimeout(() => {
      if (cycle.current <= maxCycles) {
        if (totalItems === activeSlide) {
          cycle.current += 1;
          setActiveSlide(1);
        } else {
          setActiveSlide(activeSlide + 1);
        }
      }
    }, fadeTranisitionTime);

    // end of animation - remove setTimeout
    if (cycle.current > maxCycles) {
      clearTimeout(timeout);
    }
  }, [activeSlide, totalItems]);

  useEffect(() => {
    if (children) cycleWords();
  });

  const getClassNames = (index: number) => {
    return clsx({
      initial: activeSlide === 1 && cycle.current === 1,
      active: index === activeSlide,
      // previous slide is either:
      // when index (plus one) is the same as active slide - 1 (since index starts from 0)
      // or when the first slide is active, and the index (plus one) equals the total amount of items
      previous:
        index === activeSlide - 1 ||
        (activeSlide === 1 && index === wordsCombined.length),
    });
  };

  return (
    <HeadingItems>
      {wordsCombined.map((data, index) => {
        return (
          <HeadingContainer
            key={data._key}
            className={getClassNames(index + 1)}
          >
            <HighlightPlaceholder
              colour={data.decorativeColour}
              style="straight"
              _key={data._key}
              key={index}
            >
              {data.word}
            </HighlightPlaceholder>
          </HeadingContainer>
        );
      })}
    </HeadingItems>
  );
};

export const HeadingItems = styled.span`
  position: relative;
  display: block;
  span {
    font-size: inherit;
  }
`;

export const HeadingContainer = styled.span`
  opacity: 0;
  position: absolute;
  transform: translateY(-20px);
  position: absolute;
  bottom: 0;
  will-change: transform, opacity;
  display: block;

  &&.previous {
    opacity: 0;
    transform: translateY(10px);
    transition-duration: 0.6s;
    transition-property: opacity, transform;
    transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
  }

  &&.active {
    position: relative;
    opacity: 1;
    transform: translateY(0px);
    transition-delay: 0.4s;
    transition-property: opacity, transform;
    transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
    transition-duration: 0.6s;
  }

  @media (prefers-reduced-motion) {
    &&.previous {
      transition-duration: 0s;
    }

    &&.active {
      transition-delay: 0s;
      transition-duration: 0s;
    }
  }
`;

export { Animation };
