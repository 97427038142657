import { useRef, useState } from 'react';
import styled from '@emotion/styled';
import {
  arrow,
  flip,
  FloatingArrow,
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  offset,
  safePolygon,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useId,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import clsx from 'clsx';

import { breakpoints, screen } from '@/components/common/breakpoints';
import { BulletPoints } from '@/components/common/bulletpoints';
import { Icon } from '@/components/common/Icon';
import { Paragraph } from '@/components/common/MarkUp';
import { zIndex } from '@/components/common/z-index';
import { getBulletPointColour, getTextColour } from '@/lib/colourUtils';
import { useMatchMedia } from '@/lib/useMatchMedia';
import { saans } from '@/styles/fonts';
import { bodyMedium } from '@/styles/typography';

import type {
  IconColourType,
  SurfaceColourTokenType,
  SurfaceColourType,
  TextColourTokenType,
} from '@/types/colours';
import type { SanityBlock, SanityKeyed } from '@/types/sanity';
import type { Strategy } from '@floating-ui/react';
import type { ReactElement } from 'react';

export interface MarkProps {
  heading?: string;
  content: Array<SanityKeyed<SanityBlock>>;
  tooltipColour?: SurfaceColourType;
  tickColor?: IconColourType;
}

interface TooltipContentProps {
  mark: MarkProps;
  id: string;
  isMobile?: boolean;
}

const TooltipInnerContent = ({
  mark: { heading, content, tooltipColour },
  id,
}: TooltipContentProps) => {
  const toolTipColourToken = tooltipColour?.token || '--surface-cool-strong';
  return (
    <TooltipContent
      toolTipColour={getTextColour(toolTipColourToken)}
      id={`tooltip-trigger-description-${id}`}
    >
      {!!heading && <Label size="subheading-sm">{heading}</Label>}
      {content[0].listItem === 'bullet' ? (
        <BulletPoints pointColour={getBulletPointColour(toolTipColourToken)}>
          <BulletpointsList>
            {content.map((child) =>
              child.children.map((childTwo) => (
                <li key={childTwo._key}>{childTwo.text}</li>
              )),
            )}
          </BulletpointsList>
        </BulletPoints>
      ) : (
        content.map((child) =>
          child.children.map((childTwo) => (
            <Paragraph size="body-lg" key={childTwo._key}>
              {childTwo.text}
            </Paragraph>
          )),
        )
      )}
    </TooltipContent>
  );
};

const TooltipContent = styled.div<{ toolTipColour: TextColourTokenType }>`
  p,
  li {
    color: ${({ toolTipColour }) => `var(${toolTipColour})`};
  }

  ${screen.md} {
    p,
    li {
      color: ${({ toolTipColour }) => `var(${toolTipColour})`};
    }
  }
`;

const Label = styled(Paragraph)`
  margin-bottom: var(--spacing-xx-small);
`;

const BulletpointsList = styled.ul`
  padding-top: 0 !important;

  li:last-of-type {
    padding-bottom: 0 !important;
  }
`;

export interface TooltipV2Props {
  children: string | ReactElement;
  mark: MarkProps;
  textColour?: TextColourTokenType;
}

export const TooltipV2 = ({ children, mark, textColour }: TooltipV2Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useMatchMedia(breakpoints.md);
  const arrowRef = useRef(null);
  const { x, y, strategy, refs, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      offset(8),
      flip(),
      shift(),
      arrow({
        element: arrowRef,
      }),
    ],
  });
  const hover = useHover(context, {
    handleClose: safePolygon({
      requireIntent: false,
    }),
    enabled: !isMobile,
  });
  const dismiss = useDismiss(context);
  const focus = useFocus(context);
  const role = useRole(context, {
    role: isMobile ? 'dialog' : 'tooltip',
  });
  const click = useClick(context, {
    enabled: isMobile,
  });
  const id = useId();
  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    role,
    dismiss,
    click,
  ]);

  if (!mark) return;

  return (
    <>
      <FocusItem
        tabIndex={0}
        ref={refs.setReference}
        textColour={textColour}
        {...getReferenceProps()}
        aria-describedby={`tooltip-trigger-description-${id}`}
        id={`tooltip-trigger-${id}`}
      >
        {children}
      </FocusItem>
      {isMobile && isOpen && (
        <FloatingPortal>
          <div className={clsx(saans.className, saans.variable)}>
            <FloatingOverlay
              lockScroll
              style={{
                background: '#1B1B41B8',
                zIndex: `${zIndex.tooltipOverlay}`,
              }}
            >
              <FloatingFocusManager context={context}>
                <TooltipWrapperMobile>
                  <TooltipContainer>
                    <CrossContainer
                      onClick={() => setIsOpen(false)}
                      aria-label="Close popup"
                    >
                      <Icon icon="Close" colour="--icon-action-dark" />
                    </CrossContainer>
                    <TooltipContentContainer
                      ref={refs.setFloating}
                      aria-labelledby={`tooltip-trigger-${id}`}
                      backgroundColour={
                        mark.tooltipColour?.token || '--surface-cool-strong'
                      }
                      {...getFloatingProps()}
                    >
                      <TooltipInnerContent mark={mark} id={id} isMobile />
                    </TooltipContentContainer>
                  </TooltipContainer>
                </TooltipWrapperMobile>
              </FloatingFocusManager>
            </FloatingOverlay>
          </div>
        </FloatingPortal>
      )}

      {!isMobile && isOpen && (
        <FloatingPortal>
          <div className={clsx(saans.className, saans.variable)}>
            <TooltipWrapper
              ref={refs.setFloating}
              backgroundColour={
                mark.tooltipColour?.token || '--surface-cool-strong'
              }
              top={y ?? 0}
              left={x ?? 0}
              zIndex={zIndex.tooltipOverlay}
              position={strategy}
              {...getFloatingProps()}
            >
              <FloatingArrow
                ref={arrowRef}
                context={context}
                fill={`var(${
                  mark.tickColor?.token || '--surface-cool-strong'
                })`}
              />
              <TooltipInnerContent mark={mark} id={id} />
            </TooltipWrapper>
          </div>
        </FloatingPortal>
      )}
    </>
  );
};

const FocusItem = styled.span<{ textColour?: TextColourTokenType }>`
  display: inline;
  color: ${({ textColour }) =>
    textColour ? `var(${textColour})` : 'var(--text-action)'};
`;

const TooltipWrapper = styled.div<{
  backgroundColour: SurfaceColourTokenType;
  top: number;
  left: number;
  zIndex: number;
  position: Strategy;
}>`
  border-radius: var(--spacing-xx-small);
  padding: var(--spacing-small);
  max-width: 500px;
  position: relative;
  width: max-content;
  background-color: ${({ backgroundColour }) => `var(${backgroundColour})`};
  top: ${({ top }) => `${top}px`};
  left: ${({ left }) => `${left}px`};
  z-index: ${({ zIndex }) => zIndex};
  position: ${({ position }) => position};
  color: ${({ backgroundColour }) => `var(${getTextColour(backgroundColour)})`};

  li {
    ${bodyMedium};
  }
`;

const CrossContainer = styled.button`
  width: 100%;
  background-color: var(--surface-warm-base);
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  margin-right: var(--spacing-250);

  > div {
    display: flex;
  }

  ${screen.sm} {
    margin-right: 0;
  }
`;

const TooltipWrapperMobile = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${screen.sm} {
    margin: 0 var(--spacing-small);
    justify-content: center;
  }
`;

const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  height: 100%;
  gap: var(--spacing-250);
  max-width: 540px;

  li {
    ${bodyMedium}
  }

  ${screen.sm} {
    justify-content: center;
  }
`;

const TooltipContentContainer = styled.div<{
  backgroundColour: SurfaceColourTokenType;
}>`
  background-color: ${({ backgroundColour }) => `var(${backgroundColour})`};
  color: ${({ backgroundColour }) => `var(${getTextColour(backgroundColour)})`};
  border-top-left-radius: var(--spacing-small);
  border-top-right-radius: var(--spacing-small);
  padding: var(--spacing-250);

  ${screen.sm} {
    border-bottom-left-radius: var(--spacing-small);
    border-bottom-right-radius: var(--spacing-small);
  }
`;
