import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';

import { screen } from '@/components/common/breakpoints';

import type { Spacing } from '@/types/measures';

interface RowProps {
  rowGap?: Spacing;
}

/**
 * Cols must be direct decendants of a Row container
 *
 * @param rowGap Adds a specific spacing between rows when wrapping on mobile
 */
export const Row = styled.div<RowProps>`
  position: relative;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: var(--space-component-xl);
  row-gap: ${({ rowGap = '--spacing-small' }) => `var(${rowGap})`};

  grid-auto-flow: dense;

  ${screen.sm} {
    grid-template-columns: repeat(12, 1fr);
    gap: var(--space-component-xl);
  }
`;

type Sizes4 = 1 | 2 | 3 | 4;
type Sizes12 = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

// There's no point starting on the last grid-line, so it's excluded here
type Start4 = 1 | 2 | 3;
type Start12 = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;

export type Sizes = {
  base?: Sizes4;
  sm?: Sizes12;
  md?: Sizes12;
  lg?: Sizes12;
  xl?: Sizes12;
};

type Start = {
  base?: Start4;
  sm?: Start12;
  md?: Start12;
  lg?: Start12;
  xl?: Start12;
};

interface ColProps {
  sizes: Sizes;
  start?: Start;
}

const breakpoints = ['base', 'sm', 'md', 'lg', 'xl'];
const sizeDefaults = { base: 4, sm: 12 };

// Iterate over all breakpoints and return a css snippet if a value exists for the aprticular property
const getBreakpoints = (sizes: Sizes, start: Start) => {
  const styles = [];

  const sizesWithDefault = { ...sizeDefaults, ...sizes };

  breakpoints.forEach((breakpoint) => {
    const size = sizesWithDefault[breakpoint];
    const startPosition = start[breakpoint];

    if (breakpoint === 'base') {
      styles.push(`
        grid-column-start: ${startPosition ?? ''};
        grid-column-end: span ${size ?? ''};
      `);
    }

    styles.push(`
      ${screen[breakpoint]} {
        grid-column-start: ${startPosition ?? ''};
        grid-column-end: span ${size ?? ''};
      }
    `);
  });

  return styles.join(' ');
};

// As 'sizes' is a valid prop in HTML, it gets passed onto the Col div, even though it doesn't make sense here.
const shouldForwardProp = (propName: string) =>
  isPropValid(propName) && propName !== 'sizes';

/**
 * Cols are containers for content that adhere to a grid system. This Grid system is strictly 1-dimensional, and thus should only be overflow on smaller screen sizes
 * @param sizes - A list of breakpoint-denoted values stating the size of this component in column count.
 * @param start - An optional list of breakpoint-denoted values stating where this column will start. The number is the CSS grid-line
 */
export const Col = styled('div', { shouldForwardProp })<ColProps>`
  min-width: 0;

  ${({ sizes = {}, start = {} }) => getBreakpoints(sizes, start)}
`;
