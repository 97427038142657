import { createElement } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { orderedBreakpointKeys, screen } from '@/components/common/breakpoints';

import { icons } from './Icons';

export type IconSet = keyof typeof icons;

export type IconSizesSet = {
  base?: string;
  sm?: string;
  md?: string;
  lg?: string;
  xl?: string;
};

export interface IconProps {
  icon: IconSet;
  size?: string | IconSizesSet;
  colour?: string;
  rotation?: string;
  strokeWidth?: string;
  illustrative?: boolean;
}

/**
  Icon component used to render SVG icons.

  `currentColor` is used by default, meaning the colour of the icon will be inherited from the parent. Use `color` prop to override if needed.

  @field {string} `icon` renders the desired icon from the list provided

  @field {string} `size` the size at which the icon is rendered. Defaults to 16px

  @field {string} `colour` used to override the colour of the icon. Use colour token here, e.g. `--ultraviolet-900`

 */
export const Icon = ({
  icon,
  size = '16px',
  colour,
  rotation,
  strokeWidth,
  illustrative = false,
  ...props
}: IconProps) => {
  const iconComponent = icons[icon];

  if (!iconComponent) {
    throw new Error(`No Icon component found that matches name "${icon}"`);
  }

  return (
    <IconWrapper
      size={size}
      colour={colour}
      rotation={rotation}
      strokeWidth={strokeWidth}
      illustrative={illustrative}
      {...props}
    >
      {createElement(iconComponent)}
    </IconWrapper>
  );
};

const getStyles = (size: string | IconSizesSet, illustrative: boolean) => {
  if (typeof size == 'string') {
    return `
      width: ${size};
      min-width: ${size};
      height: ${size};
      ${
        illustrative &&
        size &&
        `
          svg {
            height: ${size};
            width: ${size};
          }
        `
      }
    `;
  }
  const styles = [];

  orderedBreakpointKeys.forEach((breakpoint) => {
    if (Object.keys(size).includes(breakpoint)) {
      const breakpointSize = size[breakpoint];

      styles.push(`
      ${screen[breakpoint]} {
        width: ${breakpointSize};
        min-width: ${breakpointSize};
        height: ${breakpointSize};
        ${
          illustrative &&
          breakpointSize &&
          `
            svg {
              height: ${breakpointSize};
              width: ${breakpointSize};
            }
          `
        }
      }
    `);
    }
  });

  return styles.join(' ');
};

const IconWrapper = styled.div<{
  size: string | IconSizesSet;
  colour?: string;
  rotation?: string;
  strokeWidth?: string;
  illustrative?: boolean;
}>`
  ${({ size, illustrative }) => getStyles(size, illustrative)};

  color: ${({ colour }) => (colour ? `var(${colour})` : 'currentColor')};

  display: flex;
  ${({ rotation }) =>
    rotation &&
    css`
      svg {
        transition: transform 0.3s;
        transform: rotate(${rotation}deg);
      }
    `}
  ${({ strokeWidth }) =>
    strokeWidth &&
    css`
      path {
        stroke-width: ${strokeWidth};
      }
    `}
`;
