import styled from '@emotion/styled';

import { screen } from '@/components/common/breakpoints';
import { RichHeading } from '@/components/common/Heading';
import { HeadingLevel } from '@/components/common/MarkUp';
import { MenuItem } from '@/components/layout/NewNavigationBar/MenuItem';
import { HeaderItem } from '@/components/layout/NewNavigationBar/SubNavigation/HeaderItem';
import { useMediaQuery } from '@/lib/useMediaQuery';
import { blockUrlWithAudience } from '@/lib/utils';

import type {
  HeaderItem as HeaderItemProps,
  LinkItem,
  NavDirectLink,
  NavLinkGridRegion,
  NavLinkListRegion,
  NavPanel,
  Region as RegionType,
} from '@/components/layout/NewNavigationBar/types';
import type { SanityAudienceType } from '@/types/sanity';

interface SubNavStandardLayoutProps {
  navItem: NavPanel | NavDirectLink;
  navigationAudience?: SanityAudienceType;
  isAudienceSwitcherEnabled?: boolean;
}

export const itemRender = (
  item: LinkItem | HeaderItemProps,
  navigationAudience?: SanityAudienceType,
  isAudienceSwitcherEnabled?: boolean,
) => {
  if (item._type === 'linkItem') {
    item = item as LinkItem;

    return (
      <MenuItem
        key={item._key}
        title={item.title}
        url={blockUrlWithAudience(
          item.url,
          navigationAudience,
          navigationAudience,
          item.noAudienceToggle,
          isAudienceSwitcherEnabled,
        )}
        description={item.description}
        tier={item.tier}
      />
    );
  }
  if (item._type === 'headerItem') {
    return (
      <HeaderItem key={item._key} text={item.text} padding={item.padding} />
    );
  }
};

export const SubNavStandardLayout = ({
  navItem,
  navigationAudience,
  isAudienceSwitcherEnabled,
}: SubNavStandardLayoutProps) => {
  const isDesktop = useMediaQuery(screen.mdQuery);

  const navLinkListRegion = (
    region: NavLinkListRegion,
    separator: 'horizontal' | 'vertical',
  ) => {
    return (
      <Region
        key={region._key}
        numberOfColumns={isDesktop ? region?.columns?.length : 1}
        separator={separator}
        type={region?._type}
      >
        <HeadingLevel>
          {region.columns?.map((column) => (
            <Column key={column._key}>
              {column.items.map((item) => {
                return itemRender(
                  item,
                  navigationAudience,
                  isAudienceSwitcherEnabled,
                );
              })}
            </Column>
          ))}
        </HeadingLevel>
      </Region>
    );
  };

  const navLinkGridRegion = (
    region: NavLinkGridRegion,
    separator: 'horizontal' | 'vertical',
  ) => {
    return (
      <Region
        key={region._key}
        numberOfColumns={region?.items?.length}
        separator={separator}
        type={region?._type}
      >
        <HeadingLevel>
          {region.items?.map((item) => {
            return itemRender(
              item,
              navigationAudience,
              isAudienceSwitcherEnabled,
            );
          })}
        </HeadingLevel>
      </Region>
    );
  };

  const renderRegion = (
    region: RegionType,
    separator: 'horizontal' | 'vertical',
  ) => {
    switch (region._type) {
      case 'navLinkGridRegion':
        return navLinkGridRegion(region, separator);
      case 'navLinkListRegion':
        return navLinkListRegion(region, separator);
      default:
        return null;
    }
  };

  if (navItem._type !== 'navDirectLink') {
    const { regions, separator } = navItem;

    return (
      <Container>
        {navItem?.heading && (
          <HeadingLevel>
            <RichHeading heading={navItem?.heading} />
          </HeadingLevel>
        )}
        {regions?.map((region) => {
          return renderRegion(region, separator);
        })}
      </Container>
    );
  }
};

const Container = styled.div`
  display: grid;
  border-top: 1px solid var(--border-warm-base);
  ${screen.md} {
    border-top: none;
  }
`;
const Region = styled.div<{
  numberOfColumns: number;
  separator?: 'horizontal' | 'vertical';
  type: 'navLinkGridRegion' | 'navLinkListRegion' | 'navCardListRegion';
}>`
  display: grid;
  gap: var(--space-block-sm);
  padding-top: var(--space-block-sm);
  grid-template-columns: repeat(1, 1fr);
  &:not(:last-of-type) {
    padding: var(--space-block-md) 0;
    border-bottom: ${({ separator }) =>
      ` ${separator === 'horizontal' ? '1px solid var(--border-warm-subtle)' : 'none'}`};
  }
  ${screen.sm} {
    row-gap: var(--space-block-sm);
    column-gap: var(--space-block-md);
    grid-template-columns: ${({ type }) =>
      ` ${type === 'navLinkListRegion' ? 'repeat(1, 1fr)' : 'repeat(2, 1fr);'}`};
  }
  ${screen.md} {
    gap: var(--space-block-lg);
    grid-template-columns: ${({ numberOfColumns }) =>
      `repeat(${numberOfColumns}, 1fr);`};
  }
  ${screen.lg} {
    gap: var(--space-block-xl);
  }
`;
const Column = styled.div`
  height: min-content;
`;
