import { useEffect, useRef, useState } from 'react';

export const useScrollListener = (options) => {
  const containerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(true);
  const [isRunning, setIsRunning] = useState(true);
  const { runOnce } = options;

  useEffect(() => {
    const callbackFunc = (entries) => {
      const [entry] = entries;

      if (runOnce && entry.intersectionRatio > 0) {
        setIsRunning(false);
      }
      setIsVisible(entry.isIntersecting);
    };

    const observer = new IntersectionObserver(callbackFunc, options);
    const containerNode = containerRef.current;

    if (containerNode) {
      observer.observe(containerNode);
    }

    if (!isRunning) {
      observer.unobserve(containerNode);
    }

    return () => {
      if (containerNode) {
        observer.unobserve(containerNode);
      }
    };
  }, [containerRef, isRunning, options, runOnce]);

  return [containerRef, isVisible] as const;
};
